import '@/assets/style/index.css';
import 'font-awesome/css/font-awesome.min.css';

import 'core-js';
import Vue from 'vue';
import App from './App.vue';
import Cookies from 'js-cookie';
import ElementUI from 'element-ui';
import "element-ui/lib/theme-chalk/index.css";
import store from './store';
import router from '@/router';
import i18n from './lang';
import '@/permission';
import { hasBtnPermission } from './utils/permission'
import '@/assets/style/index.css';
import moment from 'moment'
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

Vue.prototype.$moment = moment
Vue.prototype.hasPermission = hasBtnPermission

import common from "@/utils/common";
import global from "@/utils/global";
Vue.prototype.$global = global.global_
Vue.prototype.$utils = common

Vue.use(ElementUI, {
    size: Cookies.get('size') || 'medium', // set element-ui default size
    i18n: (key, value) => i18n.t(key, value),
});

Vue.config.productionTip = false

// new Vue({
//   render: h => h(App),
// }).$mount('#app')

new Vue({
    el: '#app',
    router,
    store,
    i18n,
    render: h => h(App),
});
