import Layout from "@/layout/index";

const pageRoutes = [
    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '/',
                component: () => import('@/views/Index'),
                name: 'HomePage',
                meta: { auth: false,},
            },
        ],
    },
    {
        path: '/mt5-web',
        component: Layout,
        children: [
            {
                path: '/mt5-web',
                component: () => import('@/views/TradingPlatform/MT5Web'),
                name: 'MT5Web',
                meta: { auth: false,},
            },
        ],
    },
    {
        path: '/metatrader5',
        component: Layout,
        children: [
            {
                path: '/metatrader5',
                component: () => import('@/views/TradingPlatform/Metatrader5'),
                name: 'Metatrader5Page',
                meta: { auth: false,},
            },
        ],
    },
    {
        path: '/ezqt',
        component: Layout,
        children: [
            {
                path: '/ezqt',
                component: () => import('@/views/EZQT/EZQT'),
                name: 'EZQTPage',
                meta: { auth: false,},
            },
        ],
    },
]


export default pageRoutes;
