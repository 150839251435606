import axios from 'axios';
import { Message ,Loading} from 'element-ui';
import Vue from 'vue'
import i18n from "@/lang";
Vue.use(i18n)
import { isLogged, setLogged, removeToken, loadingFns } from '@/utils/auth';
import store from "@/store";

const baseURL = process.env.VUE_APP_URL;
// const baseURL = process.env.VUE_APP_URL;

const service = axios.create({
    baseURL,
    timeout: 100000, // Request timeout
    headers: {
        //公共请求头配置，本项目请求头大多数接口是这个，所以这里可以配置一下，对于特殊接口单独配置
        "Content-Type": "application/x-www-form-urlencoded"
    }
});

function loadingFn(){
    let loading = Loading.service({
        lock: true,
        spinner: 'fa fa-spinner fa-pulse font-32 color-000000'
    });
    return loading;
}

function loadingShow(){
    document.getElementById('loading-box').style.display = 'flex'
}

function loadingHide(){
    document.getElementById('loading-box').style.display = 'none'
}

let LS = '';

service.interceptors.request.use(
    config => {
        const token = isLogged()
        // console.log(token)
        if (token && !config.isLogged) {
            config.headers['token'] = `${token}`; // Set token
        }

        // if (!isLogged() && !config.isLogged){
        //     location.reload();
        //     return
        // }else{
        //     if (isLogged())
        //         setLogged(token);
        // }
        // if (config.url != '/login'){
        // }
        // console.log(config)
        if (config.method=='post') {
            if (config.loading !== false && config?.data?.loading !== false){
                // console.log(config.loading !== false)
                LS = loadingFn();
            }
        } else {
            if (config.params && config.params.loading == true){
                LS = loadingFn();
            }
        }

        if (config.url.indexOf('/redirect') == -1){
            Message.closeAll();
        }
        return config;
    },
    error => {
        if (LS){
            LS.close()
        }
        Promise.reject(error);
    }
);

// response pre-processing
service.interceptors.response.use(
    response => {
        if (LS){
            LS.close()
        }

        let configData = response.config && response.config.data && response.config.url != '/image/upload' ? typeof response.config.data == Object ? response.config.data : JSON.parse(response.config.data) : {}
        if (
            (configData.alert || configData.alert == undefined) && (response.data.code != 200 && response.data.msg)
        ) {
            Message({
                message:response.data.msg,
                type: 'error',
                showClose: true
            });
        }
        return response.data;
    },
    error => {
        if (LS){
            LS.close()
        }
        // console.log(error.response.status)
        let msg = error.msg;
        if (error.response?.data && error.response?.data.errors) {
            msg = error.response.data.errors;
        } else if (error.response?.data && error.response?.data.error) {
            msg = error.response.data.error;
        }
        if (error.response?.status==401){
            removeToken();
            location.reload();
        }

        Message({
            message: msg ,
            type: 'error',
            duration: 5000,
            showClose: true
        });
        return Promise.reject(error);
    }
);

export default service;

