export default {
    page: {
        "no": "No",
        "yes": "Yes",
        "mt5_website": "MT5网页版",
        "search": "搜索",
        "account_now": "立即开户",
        "login": "登入",

        "nav1": "关于我们",
        "nav1_1": "集团简介",
        "nav1_2": "监管合规",

        "nav2": "集团动态",
        "nav2_1": "集团活动",
        "nav2_2": "最新优惠",
        "nav2_3": "媒体报道",

        "nav3": "CFD差价合约",
        "nav3_1": "外汇",
        "nav3_2": "贵金属",
        "nav3_3": "股指原油",
        "nav3_4": "美股",
        "nav3_5": "加密货币",

        "nav4": "黄金商品",
        "nav4_1": "实物黄金销售",

        "nav5": "市场资讯",
        "nav5_1": "财经日曆",
        "nav5_2": "名家论坛",

        "nav6": "交易平台",
        "nav6_1": "Metatrader 5",
        "nav6_2": "网页版",

        "nav7": "客户支援",
        "nav7_1": "联系我们",
        "nav7_2": "常见问题",

        "copyright": "免责声明：本网站的内容仅供一般信息参考，不应被视为个人财务建议。在根据此处找到的任何信息采取行动之前，您应该根据个人目标、财务状况和需求评估其适用性。差价合约（CFD）和保证金外汇合约交易涉及重大风险，可能并不适合每个投资者；存在损失超过初始投资的可能性。您不拥有也不对标的资产拥有任何权利。我们强烈建议您在交易之前寻求独立意见，并确保您充分理解相关风险。在获取本网站上列出的任何产品之前，阅读并考虑相关披露文件至关重要。本网站提供的产品和服务不适用于美国居民。",

        "EURUSD": "欧美",
        "USDJPY": "美日",
        "AUDUSD": "澳美",
        "XAUUSDx": "黄金",
        "USOIL": "美油",
        "HK50": "恒指",
        "US30": "道琼斯",
        "COMMODITY": "商品",
        "CFD_US_Equities": "CFD股票合约细则（美国交易所股票）",
        "CFD_Bullion": "CFD贵金属商品合约细则",
        "CFD_Forex_Pairs": "CFD外汇商品合约",
        "CFD_Index": "CFD指数商品合约细则",

        "PPLGM": "PPLGM 是由多家金融监管机构授权",
        "Live_Chat": "Live Chat",

        "PPL_International_Limited": "PPL国际有限公司受科摩罗联盟安儒昂自治岛的安儒昂离岸金融管理局（AOFA）监管。许可证号为L15711/PPL，地址位于No.22 Nguyen Hoang Street, An Phu Ward, Thu Duc City, Ho Chi Minh City, Vietnam.",
        "PP_Link_Securities_Co": "PP Link Securities Co., Ltd, is regulated by the Securities and Exchange Regulator of Cambodia (SERC), registration number 00036349 and is located at Room 501, 5th Floor, Golden Tower, No.158-166, Street 215, Sangkat Veal Vong, Khan 7 Makara, Phnom Penh.",

        "jtjj_1": `
            <p>管理团队过去40多年在全球积聚了丰富的专业交易平台运营经验，致力为不同地区的客户提供卓越交易体验。</p>
            <p>其间经历无数的社会及经济变迁，金融商品的交易环境也随着时代进步而不断优化提升，在市场高度透明化及激烈的竞争情况下，PPL International ( PPLI )依然紧守岗位，得到所有合作伙伴及客户深厚的信任。</p>
            <p>“以人为本，以客为专”是集团创立以来的企业文化，也相信会持续与每一个PPL共同缔造双赢的结果。</p>
        `,

        "contact_us_content":`
            <div class="contact-us-content-item wow animated fadeInLeft">
                <p class="contact-us-title">
                    <span>PPL国际</span>
                </p>
                <div>
                    <img src="assets/images/icon/address_icon.png" alt="">
                    <p>No.22 Nguyen Hoang Street, An Phu Ward, Thu Duc City,<br>Ho Chi Minh City, Vietnam.</p>
                </div>
                <div>
                    <img src="assets/images/icon/phone_icon.png" alt="">
                    <p><a href="tel:84 286759905">84 286759905</a></p>
                </div>
                <div>
                    <img src="assets/images/icon/email_icon.png" alt="">
                    <p><a href="mailto:enquiry@pplgm.com">enquiry@pplgm.com</a></p>
                </div>
                <div>
                    <img src="assets/images/icon/word_icon.png" alt="">
                    <p><a href="https://www.pplgm.com" target="_blank">www.pplgm.com</a><br>注册号码: 15711</p>
                </div>
            </div>
            <div class="contact-us-content-item wow animated fadeInRight">
                <p class="contact-us-title">
                    <span>PP Link证券</span>
                </p>
                <div>
                    <img src="assets/images/icon/address_icon.png" alt="">
                    <p>Room 501, 5th Floor, Golden Tower, No.158-166, Street 215,<br>Sangkat Veal Vong, Khan 7 Makara, Phnom Penh.</p>
                </div>
                <div>
                    <img src="assets/images/icon/phone_icon.png" alt="">
                    <p><a href="tel:855 (0)23 866 622">855 (0)23 866 622</a></p>
                </div>
                <div>
                    <img src="assets/images/icon/email_icon.png" alt="">
                    <p><a href="mailto:info@pplinksecurities.com.kh">info@pplinksecurities.com.kh</a></p>
                </div>
                <div>
                    <img src="assets/images/icon/word_icon.png" alt="">
                    <p><a href="https://www.pplinksecurities.com.kh" target="_blank">www.pplinksecurities.com.kh</a><br>注册号码: 00036349</p>
                </div>
            </div>
        `,

        "rc_content1":`
            <p class="rc_content_title mb-0">Securities and Exchange Regulator of Cambodia (SERC) </p>
            <p class="rc_content_title">PP Link Securities Co., Ltd.</p>
            <p>获得柬埔寨证券交易委员会（SERC）认可的证券经纪牌照，并成为柬埔寨证券交易所（CSX）的会员</p>
            <p>获得柬埔寨证券交易委员会（SERC）认可的衍生性商品经纪牌照</p>
            <p>获得柬埔寨证券交易委员会（SERC）认可的证券上市发行销售公司牌照</p>
        `,

        "rc_content2":`
            <p class="rc_content_title mb-0">Anjouan Offshore Finance Authority (AOFA)</p>
            <p class="rc_content_title">PPL International Limited</p>
            <p>国际经纪和清算所牌照，牌照号码 L15711/PPL，由科摩罗Anjouan离岸金融管理局（AOFA）监管验证</p>
        `,

        "nax_1": `
            <p class="hj_title">全球独家代理第一金黄金产品</p>
            <p class="sub-title">人民币公斤条黄金</p>
            <p class="sub-content">全球首个离岸人民币黄金产品</p>

            <p class="sub-title">伍両 九九九九金条</p>
            <p class="sub-content">香港金银业贸易场认可炼铸9999成色公斤条</p>

            <p class="sub-title">第一金信用金币</p>
            <p class="sub-content">与瑞士著名铸金商 Argor-Heraeus 合作共同打造</p>
        `,

        "item_1": `
            <p>CFD贵金属</p>
            <div class="cfd-right">
                <p>最受欢迎的避险商品</p>
                <p>极具竞争力点差</p>
                <p>高达400倍杠杆</p>
            </div>
        `,
        "item_2": `
            <p>CFD外汇</p>
            <div class="cfd-right">
                <p>全球主要货币对</p>
                <p>点差低至0</p>
                <p>高达400倍杠杆</p>
            </div>
        `,
        "item_3": `
            <p>CFD股指原油</p>
            <div class="cfd-right">
                <p>全球主要股指原油</p>
                <p>不间断永续合约</p>
                <p>低至1%保证金</p>
            </div>
        `,
        "item_4": `
            <p>CFD美股</p>
            <div class="cfd-right">
                <p>50+星级美股</p>
                <p>苹果特斯拉谷歌</p>
                <p>高达20倍杠杆</p>
            </div>
        `,

        "ForexPairs": `
            <div class="width-100 cfd-table-bos scrollbar-x wow animated fadeInUp animation-fill-mode-initial">
                <table class="width-100 text-center" style="max-width: 1200px;" cellpadding="0" cellspacing="0">
                    <tr>
                        <th>商品</th>
                        <th>交易代码</th>
                        <th>1手合约单位</th>
                        <th>最高杠杆</th>
                        <th>最小跳动点</th>
                        <th>合约每点价值</th>
                    </tr>
                    <tr>
                        <td>澳元加元</td>
                        <td>AUDCAD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CAD</td>
                    </tr>
                    <tr>
                        <td>澳元日元</td>
                        <td>AUDJPY</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.001</td>
                        <td>100 JPY</td>
                    </tr>
                    <tr>
                        <td>澳元纽元</td>
                        <td>AUDNZD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 NZD</td>
                    </tr>
                    <tr>
                        <td>澳元美元</td>
                        <td>AUDUSD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 USD</td>
                    </tr>
                    <tr>
                        <td>加元瑞郎</td>
                        <td>CADCHF</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CHF</td>
                    </tr>
                    <tr>
                        <td>加元日元</td>
                        <td>CADJPY</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.001</td>
                        <td>100 JPY</td>
                    </tr>
                    <tr>
                        <td>瑞郎日元</td>
                        <td>CHFJPY</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.001</td>
                        <td>100 JPY</td>
                    </tr>
                    <tr>
                        <td>欧元澳元</td>
                        <td>EURAUD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 AUD</td>
                    </tr>
                    <tr>
                        <td>欧元加元</td>
                        <td>EURCAD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CAD</td>
                    </tr>
                    <tr>
                        <td>欧元瑞郎</td>
                        <td>EURCHF</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CHF</td>
                    </tr>
                    <tr>
                        <td>欧元英镑</td>
                        <td>EURGBP</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 GBP</td>
                    </tr>
                    <tr>
                        <td>欧元日元</td>
                        <td>EURJPY</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.001</td>
                        <td>100 JPY</td>
                    </tr>
                    <tr>
                        <td>欧元纽元</td>
                        <td>EURNZD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 NZD</td>
                    </tr>
                    <tr>
                        <td>欧元美元</td>
                        <td>EURUSD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 USD</td>
                    </tr>
                    <tr>
                        <td>英镑澳元</td>
                        <td>GBPAUD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 AUD</td>
                    </tr>
                    <tr>
                        <td>英镑加元</td>
                        <td>GBPCAD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CAD</td>
                    </tr>
                    <tr>
                        <td>英镑瑞郎</td>
                        <td>GBPCHF</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CHF</td>
                    </tr>
                    <tr>
                        <td>英镑日元</td>
                        <td>GBPJPY</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.001</td>
                        <td>100 JPY</td>
                    </tr>
                    <tr>
                        <td>英镑美元</td>
                        <td>GBPUSD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 USD</td>
                    </tr>
                    <tr>
                        <td>英镑纽元</td>
                        <td>GBPNZD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 NZD</td>
                    </tr>
                    <tr>
                        <td>纽元加元</td>
                        <td>NZDCAD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CAD</td>
                    </tr>
                    <tr>
                        <td>纽元瑞郎</td>
                        <td>NZDCHF</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CHF</td>
                    </tr>
                    <tr>
                        <td>纽元日元</td>
                        <td>NZDJPY</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.001</td>
                        <td>100 JPY</td>
                    </tr>
                    <tr>
                        <td>纽元美元</td>
                        <td>NZDUSD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 USD</td>
                    </tr>
                    <tr>
                        <td>美元加元</td>
                        <td>USDCAD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CAD</td>
                    </tr>
                    <tr>
                        <td>美元瑞郎</td>
                        <td>USDCHF</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CHF</td>
                    </tr>
                    <tr>
                        <td>美元日元</td>
                        <td>USDJPY</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.001</td>
                        <td>100 JPY</td>
                    </tr>
                </table>
            </div>

            <div class="ppl-flex ppl-flex-mobile-1 color-ffffff wow animated fadeInUp animation-fill-mode-initial">
                <div class="cfd-content-2 p52 ppl-flex ppl-align-center" style="background: #112445;">
                    <div>
                        <p class="cfd-title">杠杆</p>
                        <p>锁仓保证金为新仓的10%，1买1卖的1套锁单合共是1手新仓保证金的20%</p>
                        <p>开户预设交易账户杠杆为1:200, 客户可以自由调整杠杠最高达1:400</p>
                    </div>
                </div>
                <div class="cfd-content-2 p52 ppl-flex ppl-align-center" style="background: #3a61a4;">
                    <div>
                        <p class="cfd-title">交易时区及交易时段</p>
                        <p>交易系统时区: GMT+2 ( 夏令GMT+3）</p>
                        <p>系统交易时段: GMT+2/+3周一00:10-周五23:50</p>
                        <p>周中交易中断时间:无</p>
                    </div>
                </div>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #9cc2e7;">
                <p class="cfd-title" style="color: #3a61a4;">交易盈亏计算</p>
                <p style="color: #3a61a4;">交易盈亏=(卖出价格-买入价格) X 合约单位 X 合约手数</p>
                <p style="color: #3a61a4;">* 上述计算盈亏的金额的币值为报价货币的币值，譬如英镑美元，盈利的金额币值是美元<br>（排第二的货币为报价货币，排第一的货币为基础货币）</p>
                <p style="color: #3a61a4;">例子:  客户以 1.26320  价位买入 2 手英镑美元， 然后在 1.26880 价位卖出平仓</p>
                <p style="color: #3a61a4;">(1.26880-1.26320) X 100,000 X 2 = USD1,160</p>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="border: 1px solid #5f7fb5;">
                <p class="cfd-title" style="color: #595757;">过市利息计算</p>
                <p style="color: #595757;">过市利息计算及存取时间：GMT+2/+3 24:00  每星期三收取3天利息</p>
                <p style="color: #595757;">每交易日利息金额将在系统以点数计算及收取,在交易客户端的商品明细可以查看相关过夜利息点数</p>
                <p style="color: #595757;">点数如果是正点数代表可以收取利息，负点数则需要支付利息  ( 可以在交易系统的商品详细里面查看每天利息点数）</p>
                <p style="color: #595757;">例子:  客户买入 2 手 GBPUSD， 然后一天之后卖出平仓，买入合约的过市利息点数为-2</p>
                <p style="color: #595757;">100,000 X 0.00001 X (-2) X 2 X 1 = -2 USD</p>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #344592;">
                <p class="cfd-title" style="color: #ffffff;">限价单（挂单）细则</p>
                <p style="color: #ffffff;">限价单有效时间：</p>
                <p style="color: #ffffff;">一直有效，每一年第一个周末系统将自动删除所有未成交的新开单挂单指令（在仓单的TP和ST不受影响）</p>
                <p style="color: #ffffff;">正常市况下限价单挂单价格与市场价格距离：20点（2pips）</p>
                <p style="color: #ffffff;">* 当出现跳空价格，交易系统的自动停损执行机制将优先于客户在跳空范围内的挂单指令</p>
            </div>
            <div class="ppl-flex ppl-flex-mobile-1 color-ffffff wow animated fadeInUp animation-fill-mode-initial">
                <div class="cfd-content-3 ppl-flex ppl-align-center" style="background: #9cc2e7; max-width: 30%;">
                    <div>
                        <p class="cfd-title" style="color: #3a61a4;">交易价差及执行细则</p>
                        <p style="color: #3a61a4;">成交规则（包括限价单）：以市价成交</p>
                        <p style="color: #3a61a4;">价差：浮动价差</p>
                    </div>
                </div>
                <div class="cfd-content-3 ppl-flex ppl-align-center" style="background: #4e73b8;">
                    <div>
                        <p class="cfd-title" style="color: #ffffff;">单方向持仓限额及每次交易限额</p>
                        <p style="color: #ffffff;">每客户 ( 包括客户所有附加账户 ) 每外汇商品单方向持仓限额为100手标准合约</p>
                        <p style="color: #ffffff;">每次交易合约手数为 0.01-20 手，每递增 0.01 手</p>
                    </div>
                </div>
            </div>
        `,

        "Bullion": `
            <div class="width-100 cfd-table-bos scrollbar-x wow animated fadeInUp animation-fill-mode-initial">
                <div style="max-width: 1200px">
                    <table class="width-100 text-center" cellpadding="0" cellspacing="0">
                        <tr>
                            <th>商品</th>
                            <th>交易代码</th>
                            <th>1手合约单位</th>
                            <th>最高杠杆</th>
                            <th>最小跳动点</th>
                            <th>合约每点价值</th>
                        </tr>
                        <tr>
                            <td>现货黄金/美元</td>
                            <td>XAUUSD</td>
                            <td>100安士</td>
                            <td>1:400</td>
                            <td>0.01</td>
                            <td>1.0 USD</td>
                        </tr>
                        <tr>
                            <td>现货白银/美元</td>
                            <td>XAGUSD</td>
                            <td>1000安士</td>
                            <td>1:400</td>
                            <td>0.001</td>
                            <td>1.0 USD</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="ppl-flex ppl-flex-mobile-1 color-ffffff wow animated fadeInUp animation-fill-mode-initial">
                <div class="cfd-content-2 p52 ppl-flex ppl-align-center" style="background: #112445;">
                    <div>
                        <p class="cfd-title">杠杆</p>
                        <p>锁仓保证金为新仓的10%，1买1卖的1套锁单合共是1手新仓保证金的20%</p>
                        <p>开户预设交易账户杠杆为1:200, 客户可以自由调整杠杠最高达1:400</p>
                    </div>
                </div>
                <div class="cfd-content-2 p52 ppl-flex ppl-align-center" style="background: #3a61a4;">
                    <div>
                        <p class="cfd-title">交易时区及交易时段</p>
                        <p>交易系统时区: GMT+2 ( 夏令GMT+3）</p>
                        <p>系统交易时段: GMT+2/+3 周一01:01-周五23:50</p>
                        <p>周中交易中断时间:GMT+2/+3 00:00 - 01:00</p>
                    </div>
                </div>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #9cc2e7;">
                <p class="cfd-title" style="color: #3a61a4;">交易盈亏计算</p>
                <p style="color: #3a61a4;">交易盈亏=(卖出价格-买入价格) X 合约单位 X 合约手数</p>
                <p style="color: #3a61a4;">例子:  客户以 2320.60  价位买入2 手XAUUSD， 然后一天在2355.80价位卖出平仓</p>
                <p style="color: #3a61a4;">( 2355.80-2320.60 ) X 100 X 2 手= USD 7,040</p>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="border: 1px solid #5f7fb5;">
                <p class="cfd-title" style="color: #595757;">过市利息计算</p>
                <p style="color: #595757;">过市利息计算及存取时间：GMT+2/+3 23:59  每星期三收取3天利息</p>
                <p style="color: #595757;">每交易日利息金额将在系统以点数计算及收取,在交易客户端的商品明细可以查看相关过夜利息点数</p>
                <p style="color: #595757;">点数如果是正点数代表可以收取利息，负点数则需要支付利息  ( 可以在交易系统的商品详细里面查看每天利息点数）</p>
                <p style="color: #595757;">例子:  客户买入 2 手 XAUUSD， 然后一天之后卖出平仓，买入合约的过市利息点数为-15</p>
                <p style="color: #595757;">100 X 0.01 X (-15) X 2 X 1 = -30 USD</p>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #344592;">
                <p class="cfd-title" style="color: #ffffff;">限价单（挂单）细则</p>
                <p style="color: #ffffff;">限价单有效时间：</p>
                <p style="color: #ffffff;">一直有效，每一年第一个周末系统将自动删除所有未成交的新开单挂单指令（在仓单的TP和ST不受影响）</p>
                <p style="color: #ffffff;">正常市况下限价单挂单价格与市场价格距离：20 点</p>
                <p style="color: #ffffff;">* 当出现跳空价格，交易系统的自动停损执行机制将优先于客户在跳空范围内的挂单指令</p>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #112445;">
                <p class="cfd-title" style="color: #ffffff;">交易价差及执行细则</p>
                <p style="color: #ffffff;">成交规则（包括限价单）：以市价成交 （Market Execution ）</p>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #9cc2e7;">
                <p class="cfd-title" style="color: #3a61a4;">单方向持仓限额及每次交易限额</p>
                <p style="color: #3a61a4;">每客户(包括客户所有附加账户及所有价差类别)每贵金属商品单方向持仓限额为50手标准合约</p>
                <p style="color: #3a61a4;">每次交易合约手数为0.01-10 手，每递增0.01手</p>
            </div>
        `,

        "CFDIndex": `

            <div class="width-100 cfd-table-bos scrollbar-x wow animated fadeInUp animation-fill-mode-initial">
                <table class="width-100 text-center" style="max-width: 1200px;" cellpadding="0" cellspacing="0">
                    <tr>
                        <th>商品</th>
                        <th>交易代码</th>
                        <th>1手合约单位</th>
                        <th>标准保证金</th>
                        <th>最小跳动点</th>
                        <th>合约每点价值</th>
                    </tr>
                    <tr>
                        <td>德国DAX40指数</td>
                        <td>GER40</td>
                        <td>20</td>
                        <td>1%</td>
                        <td>0.1</td>
                        <td>2.0 EUR</td>
                    </tr>
                    <tr>
                        <td>香港恒生指数</td>
                        <td>HK50</td>
                        <td>50</td>
                        <td>1%</td>
                        <td>0.1</td>
                        <td>5.0 HKD</td>
                    </tr>
                    <tr>
                        <td>日经225平均指数</td>
                        <td>JPN225</td>
                        <td>500</td>
                        <td>1%</td>
                        <td>0.1</td>
                        <td>50.0 JPY</td>
                    </tr>
                    <tr>
                        <td>纳斯达克100指数</td>
                        <td>NAS100</td>
                        <td>20</td>
                        <td>1%</td>
                        <td>0.1</td>
                        <td>2.0 USD</td>
                    </tr>
                    <tr>
                        <td>美国标准普尔500指数</td>
                        <td>SPX500</td>
                        <td>50</td>
                        <td>1%</td>
                        <td>0.01</td>
                        <td>0.5 USD</td>
                    </tr>
                    <tr>
                        <td>英国富时100指数</td>
                        <td>UK100</td>
                        <td>20</td>
                        <td>1%</td>
                        <td>0.01</td>
                        <td>0.2 GBP</td>
                    </tr>
                    <tr>
                        <td>道琼斯工业30指数</td>
                        <td>US30</td>
                        <td>10</td>
                        <td>1%</td>
                        <td>0.1</td>
                        <td>1.0 USD</td>
                    </tr>
                    <tr>
                        <td>布伦特原油现货指数</td>
                        <td>UKOIL</td>
                        <td>1,000</td>
                        <td>1%</td>
                        <td>0.001</td>
                        <td>1.0 USD</td>
                    </tr>
                    <tr>
                        <td>美国原油现货指数</td>
                        <td>USOIL</td>
                        <td>1,000</td>
                        <td>1%</td>
                        <td>0.001</td>
                        <td>1.0 USD</td>
                    </tr>
                    <tr>
                        <td>富时中国A50指数</td>
                        <td>XINA50</td>
                        <td>20</td>
                        <td>1%</td>
                        <td>0.1</td>
                        <td>2.0 USD</td>
                    </tr>
                </table>
            </div>

            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #112445;">
                <p class="cfd-title" style="color: #ffffff;">杠杆</p>
                <p style="color: #ffffff;">CFD指数商品杠杆比例统一上述标准保证金，交易账户杠杆比例调整不适用于CFD指数商品</p>
                <p style="color: #ffffff;">锁仓保证金为新仓的10%，1买1卖的1套锁单合共是1手新仓保证金的20%</p>
            </div>

            <div class="cfd-table-2" style="background: #3a61a4;">
                <p class="cfd-title" style="color: #ffffff; padding: 0 15px;">交易时区及交易时段</p>
                <p style="color: #ffffff; padding: 0 15px;">交易系统时区: GMT+2 ( 夏令GMT+3）</p>
                <div class="scrollbar-x">
                    <table class="width-100 text-center" cellpadding="0" cellspacing="0">
                        <tr>
                            <td>GER40</td>
                            <td>GMT+2/+3 周一09:00-周五23：00, 周中交易中断时段: 23:00-09:00</td>
                        </tr>
                        <tr>
                            <td>HK50</td>
                            <td>GMT+2 周一至周五: 03:15-06:00 / 07:00-10:30 / 11:15-17:45</td>
                        </tr>
                        <tr>
                            <td>JPN225</td>
                            <td>GMT+2/+3 周一01:01-周五23:40, 周中交易中断时段: 00:00-01:00</td>
                        </tr>
                        <tr>
                            <td>NAS100</td>
                            <td>GMT+2/+3 周一01:01-周五23:40, 周中交易中断时段: 00:00-01:00</td>
                        </tr>
                        <tr>
                            <td>SPX500</td>
                            <td>GMT+2/+3 周一01:01-周五23:40, 周中交易中断时段: 00:00-01:00</td>
                        </tr>
                        <tr>
                            <td>UK100</td>
                            <td>GMT+2/+3 周一09:00-周五23:00, 周中交易中断时段: 23:00-09:00</td>
                        </tr>
                        <tr>
                            <td>US30</td>
                            <td>GMT+2/+3 周一01:01-周五23:40, 周中交易中断时段: 00:00-01:00</td>
                        </tr>
                        <tr>
                            <td>UKOIL</td>
                            <td>GMT+2/+3 周一03:01-周五23:40, 周中交易中断时段: 00:00-01:00</td>
                        </tr>
                        <tr>
                            <td>USOIL</td>
                            <td>GMT+2/+3 周一01:01-周五23:40, 周中交易中断时段: 00:00-01:00</td>
                        </tr>
                        <tr>
                            <td>XINA50</td>
                            <td>GMT+2 周一至周五: 03:00-10:30 / 11:00-22:40</td>
                        </tr>
                    </table>
                </div>
                <p style="color: #ffffff; padding: 0 15px;">=* HK50 及 XINA50 在系统夏令时区GMT+3时候，系统显示时间将顺延一小时</p>
            </div>

            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #9cc2e7;">
                <p class="cfd-title" style="color: #3a61a4;">交易盈亏计算</p>
                <p style="color: #3a61a4;">交易盈亏 = (卖出价格-买入价格) X 合约单位 X 合约手数</p>
                <p style="color: #3a61a4;">* 上述计算盈利的金额的币值为每点价值的币值，譬如UK100，盈利的金额币值是英镑</p>
                <p style="color: #3a61a4;">例子:  客户以 8020 价位买入 2 手UK100， 然后在8080 价位卖出平仓</p>
                <p style="color: #3a61a4;">( 8080 - 8020 ) X 10 X 2 手  = GBP 1,200</p>
            </div>

            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="border: 1px solid #5f7fb5;">
                <p class="cfd-title" style="color: #3a61a4;">过市利息计算</p>
                <p style="color: #3a61a4;">过市利息计算及存取时间：GMT+2/+3 24:00 每星期五收取3天利息</p>
                <p style="color: #3a61a4;">每交易日利息金额 = 合约手数 X 买/卖过市利息点数 X 每点价值</p>
                <p style="color: #3a61a4;">例子:  客户买入 2 手 HK50， 然后一天之后卖出平仓，买入过市利息点数为-22</p>
                <p style="color: #3a61a4;">2 X -22 X HKD 5.0 = HKD -220</p>
            </div>

            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #112445;">
                <p class="cfd-title" style="color: #ffffff;">股息Dividend及其它影响股价的计算</p>
                <p style="color: #ffffff;">因为在交易股票股指CFD时您实际上并不直接拥有实体股票，所以并没有实质股指成分股股票的股息发放或任何认购权发行及拆股等等类似事件的权利。但为确保成分股股价上涨/下跌后，对您的CFD股指持仓没有实质不构成影响，将自动在您的账户余额中存入/扣取这些额外的损失/利润<br>（取决于客户持仓仓位的方向）</p>
                <p style="color: #ffffff;">相关交易账户的调整将于该影响成分股股价事项的纳入执行的计算日期的上一个交易日收市后存入或扣取，譬如某成分股股息除息日是3月20日，客户在19日指定计算股指CFD持仓结算的时间继续持有该成分股股指CFD仓位的情况下，将纳入该股息事项的余额调整计算名单</p>
                <p style="color: #ffffff;">有关股指CFD股息调整及调整时间可以参考公司网站公布</p>
            </div>

            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #344592;">
                <p class="cfd-title" style="color: #ffffff;">限价单（挂单）细则</p>
                <p style="color: #ffffff;">限价单有效时间：</p>
                <p style="color: #ffffff;">一直有效，每一年第一个周末系统将自动删除所有未成交的新开单挂单指令（在仓单的TP和ST不受影响）</p>
                <p style="color: #ffffff;">正常市况下限价单挂单价格与市场价格距离：20 点</p>
                <p style="color: #ffffff;">* 当出现跳空价格，交易系统的自动停损执行机制将优先于客户在跳空范围内的挂单指令</p>
            </div>
            <div class="ppl-flex ppl-flex-mobile-1 color-ffffff wow animated fadeInUp animation-fill-mode-initial">
                <div class="cfd-content-3 ppl-flex ppl-align-center" style="background: #9cc2e7; max-width: 30%;">
                    <div>
                        <p class="cfd-title" style="color: #3a61a4;">交易价差及执行细则</p>
                        <p style="color: #3a61a4;">成交规则（包括限价单）：以市价成交</p>
                        <p style="color: #3a61a4;">价差：浮动价差</p>
                    </div>
                </div>
                <div class="cfd-content-3 ppl-flex ppl-align-center" style="background: #4e73b8;">
                    <div>
                        <p class="cfd-title" style="color: #ffffff;">单方向持仓限额及每次交易限额</p>
                        <p style="color: #ffffff;">每客户(包括客户所有附加账户)限额为5手标准合约, 恒生指数最大限额为20手</p>
                        <p style="color: #ffffff;">每次交易合约手数为 0.01-5 手，每递增 0.01 手</p>
                    </div>
                </div>
            </div>
        `,

        "USEquities": `
            <div class="width-100 cfd-table-bos scrollbar-x wow animated fadeInUp animation-fill-mode-initial">
                <table class="width-100 text-center" style="max-width: 1200px;" cellpadding="0" cellspacing="0">
                    <tr>
                        <th>商品</th>
                        <th>交易代码</th>
                        <th>新仓保证金</th>
                        <th>最小交易合约</th>
                        <th>最大持仓限额</th>
                    </tr>
                    <tr>
                        <td>苹果</td>
                        <td>#AAPL</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>奥多比</td>
                        <td>#ADBE</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>自动数据处理</td>
                        <td>#ADP</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>安进</td>
                        <td>#AMGN</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>亚马逊</td>
                        <td>#AMZN</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>阿斯麦</td>
                        <td>#ASML</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>博通</td>
                        <td>#AVGO</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>美国运通</td>
                        <td>#AXP</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>波音</td>
                        <td>#BA</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>百健</td>
                        <td>#BIIB</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>伯克希尔-哈撒韦</td>
                        <td>#BRK.B</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>卡特彼勒</td>
                        <td>#CAT</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Charter通信</td>
                        <td>#CHTR</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>好市多</td>
                        <td>#COST</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>思科系统</td>
                        <td>#CSCO</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>雪佛龙</td>
                        <td>#CVX</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>多宝箱</td>
                        <td>#DBX</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>沃特迪士尼</td>
                        <td>#DIS</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>易贝</td>
                        <td>#EBAY</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>亿客行</td>
                        <td>#EXPE</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>福特汽车</td>
                        <td>#F</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>通用电气</td>
                        <td>#GE</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>谷歌</td>
                        <td>#GOOG</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>高盛集团</td>
                        <td>#GS</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>家得宝</td>
                        <td>#HD</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>康宝莱</td>
                        <td>#HLF</td>
                        <td>20%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>国际商业机器</td>
                        <td>#IBM</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>英特尔</td>
                        <td>#INTC</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>强生</td>
                        <td>#JNJ</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>摩根大通</td>
                        <td>#JPM</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>可口可乐</td>
                        <td>#KO</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>洛克希德马丁</td>
                        <td>#LMT</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>来福车</td>
                        <td>#LYFT</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>万事达卡</td>
                        <td>#MA</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>万豪国际</td>
                        <td>#MAR</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>麦当劳</td>
                        <td>#MCD</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>META</td>
                        <td>#META</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>明尼苏达矿业制造</td>
                        <td>#MMM</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>默克药厂</td>
                        <td>#MRK</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>莫德纳</td>
                        <td>#MRNA</td>
                        <td>20%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>摩根士丹利</td>
                        <td>#MS</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>微软</td>
                        <td>#MSFT</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>奈飞</td>
                        <td>#NFLX</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>耐克</td>
                        <td>#NKE</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>英伟达</td>
                        <td>#NVDA</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>百事可乐</td>
                        <td>#PEP</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>辉瑞制药</td>
                        <td>#PFE</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>宝洁</td>
                        <td>#PG</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>贝宝</td>
                        <td>#PYPL</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>星巴克</td>
                        <td>#SBUX</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>美国电话电报</td>
                        <td>#T</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>塔吉特</td>
                        <td>#TGT</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>丰田汽车</td>
                        <td>#TM</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>旅行家</td>
                        <td>#TRV</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>特斯拉</td>
                        <td>#TSLA</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>台湾积体电路制造</td>
                        <td>#TSM</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>德州仪器</td>
                        <td>#TXN</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>维萨卡</td>
                        <td>#V</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>威讯通信</td>
                        <td>#VZ</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>富国银行</td>
                        <td>#WFC</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>沃尔玛</td>
                        <td>#WMT</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>永利度假村</td>
                        <td>#WYNN</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>埃克森美孚</td>
                        <td>#XOM</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                </table>
            </div>

            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="border: 1px solid #5f7fb5;">
                <p class="cfd-title" style="color: #3a61a4;">杠杆</p>
                <p style="color: #595757;">每1手相等于1股，最大杠杆是1：20，等于合约价值的5%</p>
                <p style="color: #595757;">锁仓保证金为新仓的10%，1买1卖的1套锁单合共是1手新仓保证金的20%</p>
                <p style="color: #595757;">交易账户的杠杆比率不适用于CFD股票商品</p>
            </div>
            <div class="ppl-flex ppl-flex-mobile-1 color-ffffff wow animated fadeInUp animation-fill-mode-initial">
                <div class="cfd-content-2 ppl-flex ppl-align-center" style="background: #112445;">
                    <div>
                        <p class="cfd-title">交易时区及交易时段</p>
                        <p>交易系统时区: GMT+2 ( 夏令GMT+3）</p>
                        <p>美国股票系统交易时段：周一至周五GMT+2/+3 ：16：30-23：00</p>
                    </div>
                </div>
                <div class="cfd-content-2 ppl-flex ppl-align-center" style="background: #3a61a4;">
                    <div>
                        <p class="cfd-title">交易手续费</p>
                        <p>合约买入价格之千分4，每交易最低手续费为10美元（所有账户类别统一标准）</p>
                        <p>例子客户以250美元价格买入20手(20股)苹果公司的CFD股票</p>
                        <p>手续费为：250 X 20 X 0.004 = 20美元</p>
                        <p>* CFD股票的交易手续费为固定标准，不同于交易账户其它商品收取的手续费标准</p>
                    </div>
                </div>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #9cc2e7;">
                <p class="cfd-title" style="color: #3a61a4;">交易盈亏计算</p>
                <p style="color: #3a61a4;">交易盈亏=(卖出价格-买入价格) X 合约手数</p>
                <p style="color: #3a61a4;">例子客户以250美元价格买入20手苹果公司CFD股票，然后以260美元卖出平仓</p>
                <p style="color: #3a61a4;">盈亏为：( 260-250 ) X 20 = 200美元</p>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="border: 1px solid #5f7fb5;">
                <p class="cfd-title" style="color: #3a61a4;">过市利息计算</p>
                <p style="color: #3a61a4;">过市利息计算及存取时间：GMT+2/+3 24:00  每星期五收取3天利息</p>
                <p style="color: #3a61a4;">每交易日利息金额 = 开仓价格 X 合约手数 X 买/卖过市利息 / 360</p>
                <p style="color: #3a61a4;">例子客户以250美元价格买入20手苹果公司CFD股票，持仓一天，买入利率为-4.5%</p>
                <p style="color: #3a61a4;">当天利息金额为：250 X 20 X -4.5%/360 = 0.63美元</p>
                <p style="color: #3a61a4;">CFD股票过市利率的计算因素包含市场上买卖方持仓的比例，假如市场上90%的参与者都看好某个市场或某个股票，该市场的股票或某个股票的买方需要支付的利息将较卖方需要支出的有明显差别，甚至卖方可以收取过市利息</p>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #112445;">
                <p class="cfd-title" style="color: #ffffff;">股息Dividend及其它影响股价的计算</p>
                <p style="color: #ffffff;">因为在交易股票CFD时您实际上并不直接拥有实体股票，所以并没有实质该股票的股息发放或任何认购权发行及拆股等等类似事件的权利。但为确保股价上涨/下跌后，对您的持仓没有实质不构成影响，将自动在您的账户余额中存入/扣取这些额外的损失/利润<br>（取决于客户持仓仓位的方向）</p>
                <p style="color: #ffffff;">相关交易账户的调整将于该影响个股股价事项的纳入执行的计算日期的上一个交易日收市后存入或扣取，譬如个股股息除息日是3月20日，客户在19日收市继续持有该个股CFD仓位的情况下，将纳入该股息事项的余额调整计算名单</p>
                <p style="color: #ffffff;">有关个股股息调整及其它个股有关事项可以参考该个股交易所网站公布</p>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #344592;">
                <p class="cfd-title" style="color: #ffffff;">单方向持仓限额及每次交易限额</p>
                <p style="color: #ffffff;">每客户(包括客户所有附加账户)每CFD股票商品单方向持仓限额参照上述列表</p>
                <p style="color: #ffffff;">每次交易合约手数为1-100手，但需要符合最大持仓量要求，可以参考上述列表</p>
            </div>
            <div class="ppl-flex ppl-flex-mobile-1 color-ffffff wow animated fadeInUp animation-fill-mode-initial">
                <div class="cfd-content-3 ppl-flex ppl-align-center" style="background: #9cc2e7; max-width: 30%;">
                    <div>
                        <p class="cfd-title" style="color: #3a61a4;">交易价差及执行细则</p>
                        <p style="color: #3a61a4;">成交规则（包括限价单）：以市价成交</p>
                        <p style="color: #3a61a4;">价差：浮动价差</p>
                    </div>
                </div>
                <div class="cfd-content-3 ppl-flex ppl-align-center" style="background: #4e73b8;">
                    <div>
                        <p class="cfd-title" style="color: #ffffff;">限价单（挂单）细则</p>
                        <p style="color: #ffffff;">所有限价单均只当天有效或客户自定有效时间（以较短时间执行）</p>
                        <p style="color: #ffffff;">限价单挂单价格与市场价格距离：按照每个CFD股票的价格不一样，可以在交易平台点击相关股票的详细数据查看</p>
                    </div>
                </div>
            </div>
        `,

        "footer_box": `
        <div class="footer-ltn-list">
            <div>
                <p>PPL国际有限公司受科摩罗联盟安儒昂自治岛的安儒昂离岸金融管理局（AOFA）监管。许可证号为L15711/PPL，地址位于No.22 Nguyen Hoang Street, An Phu Ward, Thu Duc City, Ho Chi Minh City, Vietnam.</p>
                <div>
                    <i class="el-icon-message mr-3px font-18"></i>
                    电子邮箱：<a href="mailto:enquiry@pplgm.com">enquiry@pplgm.com</a>
                </div>
            </div>
            <div>
                <p>PP Link证券有限公司受柬埔寨证券交易委员会（SERC）监管。注册号为00036349，地址位于Room 501, 5th Floor, Golden Tower, No.158-166, Street 215, Sangkat Veal Vong, Khan 7 Makara, Phnom Penh.</p>
                <div>
                    <i class="el-icon-message mr-3px font-18"></i>
                    电子邮箱：<a href="mailto:info@pplinksecurities.com.kh">info@pplinksecurities.com.kh</a>
                </div>
            </div>
        </div>
        `,
    }
};
