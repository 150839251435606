import { getCommon } from "@/api/common";
import { bannerList,menuList } from "@/api/page";
import store from "@/store";
import {getInfo} from "@/api/auth";
import Vue from 'vue';
import i18n from "@/lang";
import {getCookies, setCookies} from "@/utils/auth";
Vue.use(i18n)

const state = {
    common:{},
    banner:[],
    menu:[],
    loginAccount:localStorage.getItem('loginAccount')?JSON.parse(localStorage.getItem('loginAccount')):{},
}

const mutations = {
    SET_COMMON: (state, common) => {
        state.common = common;
    },
    SET_BANNER: (state, banner) => {
        state.banner = banner;
    },
    SET_MENU: (state, menu) => {
        state.menu = menu;
    },
    SET_LOGIN_ACCOUNT: (state, loginAccount) => {
        state.loginAccount = loginAccount;
        localStorage.setItem('loginAccount',JSON.stringify(loginAccount))
    },
}

const actions = {
    getCommon({ commit, state }) {
        return new Promise((resolve, reject) => {
            getCommon().then(response => {
                const common = response;
                if (!common || common.length <= 0) {
                    reject('getCommon: 公共数据不存在!');
                }
                // let user_permission = common.user_permission
                // store.dispatch('user/setUserPermissions',user_permission);

                let get_country_lang = getCookies('country_lang')
                console.log(get_country_lang,'get_country_lang')
                let country_code = common.country_code
                console.log(country_code,'country_code')
                console.log(!get_country_lang,'country_code------')
                // if (!get_country_lang || get_country_lang.lang!==country_code){
                if (!get_country_lang){
                    let langList = common.language
                    let langObj = langList.find(item=>item.pscl_code === country_code) || langList.find(item=>item.pscl_translate_language)
                    let defaultLang = langObj ? langObj.pscl_language : 'en'
                    i18n.locale = defaultLang;
                    store.dispatch('app/setLanguage', defaultLang);
                    setCookies({key:'country_lang',seconds:1800,value:{lang:langObj.pscl_code}})
                    console.log('-----set lang-----')
                }

                commit('SET_COMMON', common);
                resolve(common);
            })
            .catch(error => {
                reject(error);
            });
        });
    },
    getBannerList({ commit, state }) {
        return new Promise((resolve, reject) => {
            bannerList({language:i18n.locale}).then(response => {
                    commit('SET_BANNER', response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    getMenuList({ commit, state }) {
        return new Promise((resolve, reject) => {
            menuList({language:i18n.locale}).then(response => {
                    commit('SET_MENU', response);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },
    setLoginAccount({ commit }, loginAccount) {
        commit('SET_LOGIN_ACCOUNT', loginAccount);
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};

