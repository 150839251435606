export default {
    page: {
        "no": "No",
        "yes": "Yes",
        "mt5_website": "MT5 Web",
        "search": "Search",
        "account_now": "Register Now",
        "login": "Sign In",

        "nav1": "About Us",
        "nav1_1": "PPL International",
        "nav1_2": "Regulatory Compliance",

        "nav2": "Group News",
        "nav2_1": "Group Events",
        "nav2_2": "Latest Offers",
        "nav2_3": "Media Reports",

        "nav3": "CFD Products",
        "nav3_1": "Forex",
        "nav3_2": "Bullion",
        "nav3_3": "Index",
        "nav3_4": "US Equities",
        "nav3_5": "Crypto",

        "nav4": "Gold Products",
        "nav4_1": "First Gold Sole Agent",

        "nav5": "Market Insights",
        "nav5_1": "Economic Calendar",
        "nav5_2": "Expert Analysis",

        "nav6": "Trading Platform",
        "nav6_1": "Metatrader 5",
        "nav6_2": "Web Terminal",

        "nav7": "Client Support",
        "nav7_1": "Contact Us",
        "nav7_2": "FAQ",

        "copyright": "Disclaimer：The content on this website is intended for general informational purposes only and should not be taken as personal financial advice. Before acting on any information found here, you should evaluate its suitability based on your personal goals, financial situation, and needs. Trading in CFDs and Margin FX Contracts involves substantial risks and may not be appropriate for every investor; there is a possibility of losing more than your initial investment. You do not own or have any rights to the underlying assets. We strongly advise you to seek independent advice and ensure you fully comprehend the associated risks before trading. It is crucial to read and consider the relevant disclosure documents before acquiring any products listed on this website. The products and services offered here are not intended for residents of the United States.",

        "EURUSD": "EURUSD",
        "USDJPY": "USDJPY",
        "AUDUSD": "AUDUSD",
        "XAUUSDx": "XAUUSD",
        "USOIL": "USOIL",
        "HK50": "HK50",
        "US30": "US30",
        "COMMODITY": "COMMODITY",
        "CFD_US_Equities": "CFD US Equities",
        "CFD_Bullion": "CFD Bullion",
        "CFD_Forex_Pairs": "CFD Forex Pairs",
        "CFD_Index": "CFD Index",

        "PPLGM": "PPLGM 是由多家金融監管機構授權",
        "Live_Chat": "Live Chat",

        "PPL_International_Limited": "PPL International Limited is regulated by the Anjouan Offshore Finance Authority (AOFA) in the Autonomous Island of Anjouan, Union of Comoros. License number L15711/PPL and is registered at Hamchako, Mutsamudum The Autonomous Island of Anjouan, Union of Comoros.",
        "PP_Link_Securities_Co": "PP Link Securities Co., Ltd, is regulated by the Securities and Exchange Regulator of Cambodia (SERC), registration number 00036349 and is located at Room 501, 5th Floor, Golden Tower, No.158-166, Street 215, Sangkat Veal Vong, Khan 7 Makara, Phnom Penh.",

        "jtjj_1": `
            <p>Management team has accumulated extensive professional experience in operating trading platforms globally over the past 40 years, dedicated to providing an exceptional trading experience for clients in different regions.</p>
            <p>Throughout numerous social and economic changes, the trading environment for financial products has continuously improved and optimized with the progress of the times. Even in a highly transparent market with intense competition, PPL International ( PPLI ) has remained steadfast in its position, earning deep trust from all partners and clients.</p>
            <p>"Human-Oriented, Client-Dedicated" has been the corporate culture since the group's establishment, and we believe it will continue to create win-win results with every PPL.</p>
        `,

        "contact_us_content":`
            <div class="contact-us-content-item wow animated fadeInLeft">
                <p class="contact-us-title">
                    <span>PPL International</span>
                </p>
                <div>
                    <img src="assets/images/icon/address_icon.png" alt="">
                    <p>No.22 Nguyen Hoang Street, An Phu Ward, Thu Duc City,<br>Ho Chi Minh City, Vietnam.</p>
                </div>
                <div>
                    <img src="assets/images/icon/phone_icon.png" alt="">
                    <p><a href="tel:84 286759905">84 286759905</a></p>
                </div>
                <div>
                    <img src="assets/images/icon/email_icon.png" alt="">
                    <p><a href="mailto:enquiry@pplgm.com">enquiry@pplgm.com</a></p>
                </div>
                <div>
                    <img src="assets/images/icon/word_icon.png" alt="">
                    <p><a href="https://www.pplgm.com" target="_blank">www.pplgm.com</a><br>Register No.: 15711</p>
                </div>
            </div>
            <div class="contact-us-content-item wow animated fadeInRight">
                <p class="contact-us-title">
                    <span>PP Link Securities</span>
                </p>
                <div>
                    <img src="assets/images/icon/address_icon.png" alt="">
                    <p>Room 501, 5th Floor, Golden Tower, No.158-166, Street 215,<br>Sangkat Veal Vong, Khan 7 Makara, Phnom Penh.</p>
                </div>
                <div>
                    <img src="assets/images/icon/phone_icon.png" alt="">
                    <p><a href="tel:855 (0)23 866 622">855 (0)23 866 622</a></p>
                </div>
                <div>
                    <img src="assets/images/icon/email_icon.png" alt="">
                    <p><a href="mailto:info@pplinksecurities.com.kh">info@pplinksecurities.com.kh</a></p>
                </div>
                <div>
                    <img src="assets/images/icon/word_icon.png" alt="">
                    <p><a href="https://www.pplinksecurities.com.kh" target="_blank">www.pplinksecurities.com.kh</a><br>Register No.: 00036349</p>
                </div>
            </div>
        `,

        "rc_content1":`
            <p class="rc_content_title mb-0">Securities and Exchange Regulator of Cambodia (SERC) </p>
            <p class="rc_content_title">PP Link Securities Co., Ltd.</p>
            <p>Securities Broker license recognized by Securities and Exchange Regulator of Cambodia (SERC) and as a member of Cambodia Securities Exchange (CSX)</p>
            <p>Derivatives Broker license recognized by Securities and Exchange Regulator of Cambodia (SERC)</p>
            <p>Securities Selling Firm license recognized by Securities and Exchange Regulator of Cambodia (SERC)</p>
        `,

        "rc_content2":`
            <p class="rc_content_title mb-0">Anjouan Offshore Finance Authority (AOFA)</p>
            <p class="rc_content_title">PPL International Limited</p>
            <p>International Brokerage and Clearing House Licence Number L15711/PPL, verification regulated by The Anjouan Offshore Finance Authority (AOFA)</p>
        `,

        "nax_1": `
            <p class="hj_title">Exclusive Global Agent for First Gold Products</p>
            <p class="sub-title">RMB Kilobar Gold</p>
            <p class="sub-content">The world's first offshore RMB gold product</p>

            <p class="sub-title">5 Taels 9999 gold bar</p>
            <p class="sub-content">CGSE accredited refining 9999 1 Kilo Gold</p>

            <p class="sub-title">First Gold Credit Gold Coin</p>
            <p class="sub-content">Created cooperation with the famous Swiss goldsmith Argor-Heraeus</p>
        `,

        "item_1": `
            <p>CFD Bullion</p>
            <div class="cfd-right">
                <p>Leading Risk-Off Assets</p>
                <p>Competitive Spreads</p>
                <p>Up to 400x Leverage</p>
            </div>
        `,
        "item_2": `
            <p>CFD Forex</p>
            <div class="cfd-right">
                <p>Global Major Pairs</p>
                <p>Zero Spread Trading</p>
                <p>Up to 400x Leverage</p>
            </div>
        `,
        "item_3": `
            <p>CFD Index</p>
            <div class="cfd-right">
                <p>Global Equity & Oil Index</p>
                <p>Perpetual Contracts</p>
                <p>1% Initial Margin</p>
            </div>
        `,
        "item_4": `
            <p>CFD US Equities</p>
            <div class="cfd-right">
                <p>50+ Top-Rated Stocks</p>
                <p>Apple Tesla Google</p>
                <p>Up to 20x Leverage</p>
            </div>
        `,

        "ForexPairs": `
            <div class="width-100 cfd-table-bos scrollbar-x wow animated fadeInUp animation-fill-mode-initial">
                <table class="width-100 text-center" style="max-width: 1200px;" cellpadding="0" cellspacing="0">
                    <tr>
                        <th>Pairs</th>
                        <th>Symbol</th>
                        <th>Contract Size</th>
                        <th>Maximum Leverage</th>
                        <th>Tick Size</th>
                        <th>Tick Value</th>
                    </tr>
                    <tr>
                        <td>AUD/CAD</td>
                        <td>AUDCAD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CAD</td>
                    </tr>
                    <tr>
                        <td>AUD/JPY</td>
                        <td>AUDJPY</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.001</td>
                        <td>100 JPY</td>
                    </tr>
                    <tr>
                        <td>AUD/NZD</td>
                        <td>AUDNZD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 NZD</td>
                    </tr>
                    <tr>
                        <td>AUD/USD</td>
                        <td>AUDUSD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 USD</td>
                    </tr>
                    <tr>
                        <td>CAD/CHF</td>
                        <td>CADCHF</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CHF</td>
                    </tr>
                    <tr>
                        <td>CAD/JPY</td>
                        <td>CADJPY</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.001</td>
                        <td>100 JPY</td>
                    </tr>
                    <tr>
                        <td>CHF/JPY</td>
                        <td>CHFJPY</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.001</td>
                        <td>100 JPY</td>
                    </tr>
                    <tr>
                        <td>EUR/AUD</td>
                        <td>EURAUD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 AUD</td>
                    </tr>
                    <tr>
                        <td>EUR/CAD</td>
                        <td>EURCAD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CAD</td>
                    </tr>
                    <tr>
                        <td>EUR/CHF</td>
                        <td>EURCHF</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CHF</td>
                    </tr>
                    <tr>
                        <td>EUR/GBP</td>
                        <td>EURGBP</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 GBP</td>
                    </tr>
                    <tr>
                        <td>EUR/JPY</td>
                        <td>EURJPY</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.001</td>
                        <td>100 JPY</td>
                    </tr>
                    <tr>
                        <td>EUR/NZD</td>
                        <td>EURNZD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 NZD</td>
                    </tr>
                    <tr>
                        <td>EUR/USD</td>
                        <td>EURUSD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 USD</td>
                    </tr>
                    <tr>
                        <td>GBP/AUD</td>
                        <td>GBPAUD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 AUD</td>
                    </tr>
                    <tr>
                        <td>GBP/CAD</td>
                        <td>GBPCAD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CAD</td>
                    </tr>
                    <tr>
                        <td>GBP/CHF</td>
                        <td>GBPCHF</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CHF</td>
                    </tr>
                    <tr>
                        <td>GBP/JPY</td>
                        <td>GBPJPY</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.001</td>
                        <td>100 JPY</td>
                    </tr>
                    <tr>
                        <td>GBP/USD</td>
                        <td>GBPUSD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 USD</td>
                    </tr>
                    <tr>
                        <td>GBP/NZD</td>
                        <td>GBPNZD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 NZD</td>
                    </tr>
                    <tr>
                        <td>NZD/CAD</td>
                        <td>NZDCAD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CAD</td>
                    </tr>
                    <tr>
                        <td>NZD/CHF</td>
                        <td>NZDCHF</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CHF</td>
                    </tr>
                    <tr>
                        <td>NZD/JPY</td>
                        <td>NZDJPY</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.001</td>
                        <td>100 JPY</td>
                    </tr>
                    <tr>
                        <td>NZD/USD</td>
                        <td>NZDUSD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 USD</td>
                    </tr>
                    <tr>
                        <td>USD/CAD</td>
                        <td>USDCAD</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CAD</td>
                    </tr>
                    <tr>
                        <td>USD/CHF</td>
                        <td>USDCHF</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.00001</td>
                        <td>1 CHF</td>
                    </tr>
                    <tr>
                        <td>USD/JPY</td>
                        <td>USDJPY</td>
                        <td>100,000</td>
                        <td>1:400</td>
                        <td>0.001</td>
                        <td>100 JPY</td>
                    </tr>
                </table>
            </div>

            <div class="ppl-flex ppl-flex-mobile-1 color-ffffff wow animated fadeInUp animation-fill-mode-initial">
                <div class="cfd-content-2 p52 ppl-flex ppl-align-center" style="background: #112445;">
                    <div>
                        <p class="cfd-title">Leverage</p>
                        <p>The margin for hedging is 10% of the new position, and a set of one buy and one sell order together requires 20% of the margin for a new position of 1 lot.</p>
                        <p>The default trading account leverage is 1:200, and clients can freely adjust the leverage up to a maximum of 1:400.</p>
                    </div>
                </div>
                <div class="cfd-content-2 p52 ppl-flex ppl-align-center" style="background: #3a61a4;">
                    <div>
                        <p class="cfd-title">Platform Time Zone and Trading Sessions</p>
                        <p>Platform System Time Zone: GMT+2 (GMT+3 during Daylight Saving Time)</p>
                        <p>Trading Sessions: GMT+2/+3 Monday 00:10 to Friday 23:50</p>
                        <p>Mid-Week Trading Break Sessions: None</p>
                    </div>
                </div>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #9cc2e7;">
                <p class="cfd-title" style="color: #3a61a4;">Calculation of Trading Profit and Loss</p>
                <p style="color: #3a61a4;">Trading Profit and Loss = ( Selling Price - Buying Price ) × Contract Size × Number of Contracts</p>
                <p style="color: #3a61a4;">* The currency of the profit/loss amount calculated above is in the quote currency. For example, in the GBPUSD pair, the profit amount would be in US dollars.<br>( The second currency in the pair is the quote currency, while the first currency is the base currency )</p>
                <p style="color: #3a61a4;">Example: A client buys 2 lots of GBPUSD at 1.26320, then closes the position at 1.26880 :</p>
                <p style="color: #3a61a4;">(1.26880-1.26320) X 100,000 X 2 = USD1,160</p>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="border: 1px solid #5f7fb5;">
                <p class="cfd-title" style="color: #595757;">Overnight Swap Calculation</p>
                <p style="color: #595757;">Overnight Swap Calculation and Input Time: GMT+2/+3 24:00. Three days of swap are charged every Wednesday</p>
                <p style="color: #595757;">The daily swap amount is calculated in points. You can view the relevant overnight swap points in the product details on the trading terminal</p>
                <p style="color: #595757;">If the swap points are positive, it means you can receive swap; if negative, you need to pay swap</p>
                <p style="color: #595757;">Example: A client buys 2 lots of GBPUSD and close the position the next day. The overnight swap points for the long contract are -2 :</p>
                <p style="color: #595757;">100,000 X 0.00001 X (-2) X 2 X 1 = -2 USD</p>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #344592;">
                <p class="cfd-title" style="color: #ffffff;">Limit Order</p>
                <p style="color: #ffffff;">Limit Order Validity:</p>
                <p style="color: #ffffff;">Valid until cancelled. On the first weekend of each year,<br>the system will automatically delete all unfilled new pending order instructions (Take Profit and Stop Loss orders on open positions are not affected).</p>
                <p style="color: #ffffff;"> Under normal market conditions, the minimum gap between the limit order price and the market price: 20 points (2 pips)</p>
                <p style="color: #ffffff;">* When a price gap occurs, the trading system's automatic stop-loss execution mechanism will take precedence over the client's pending orders within the gap range.</p>
            </div>
            <div class="ppl-flex ppl-flex-mobile-1 color-ffffff wow animated fadeInUp animation-fill-mode-initial">
                <div class="cfd-content-3 ppl-flex ppl-align-center" style="background: #9cc2e7; max-width: 30%;">
                    <div>
                        <p class="cfd-title" style="color: #3a61a4;">Spread and Execution</p>
                        <p style="color: #3a61a4;">Execution Rules (including Limit Orders): Executed at Market Price</p>
                        <p style="color: #3a61a4;">Spread: Floating Spread</p>
                    </div>
                </div>
                <div class="cfd-content-3 ppl-flex ppl-align-center" style="background: #4e73b8;">
                    <div>
                        <p class="cfd-title" style="color: #ffffff;">Net Open Position (NOP) Limit and Per Trade Limit</p>
                        <p style="color: #ffffff;">For each client (including all sub accounts of the client), NOP limit for each forex product is 100 standard lots.</p>
                        <p style="color: #ffffff;">The number of lots per trade placing is 0.01-20 lots, with step of 0.01 lots</p>
                    </div>
                </div>
            </div>
        `,

        "Bullion": `

            <div class="width-100 cfd-table-bos scrollbar-x wow animated fadeInUp animation-fill-mode-initial">
                <div style="max-width: 1200px">
                    <table class="width-100 text-center" cellpadding="0" cellspacing="0">
                        <tr>
                            <th>Products</th>
                            <th>Symbol</th>
                            <th>Contract Size</th>
                            <th>Maximum Leverage</th>
                            <th>Tick Size</th>
                            <th>Tick Value</th>
                        </tr>
                        <tr>
                            <td>Spot XAUUSD</td>
                            <td>XAUUSD</td>
                            <td>100 oz</td>
                            <td>1:400</td>
                            <td>0.01</td>
                            <td>1.0 USD</td>
                        </tr>
                        <tr>
                            <td>Spot XAGUSD</td>
                            <td>XAGUSD</td>
                            <td>1000 oz</td>
                            <td>1:400</td>
                            <td>0.001</td>
                            <td>1.0 USD</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="ppl-flex ppl-flex-mobile-1 color-ffffff wow animated fadeInUp animation-fill-mode-initial">
                <div class="cfd-content-2 p52 ppl-flex ppl-align-center" style="background: #112445;">
                    <div>
                        <p class="cfd-title">Leverage</p>
                        <p>The margin for hedging is 10% of the new position, and a set of one buy and one sell order together requires 20% of the margin for a new position of 1 lot.</p>
                        <p>The default trading account leverage is 1:200, and clients can freely adjust the leverage up to a maximum of 1:400.</p>
                    </div>
                </div>
                <div class="cfd-content-2 p52 ppl-flex ppl-align-center" style="background: #3a61a4;">
                    <div>
                        <p class="cfd-title">Platform Time Zone and Trading Sessions</p>
                        <p>Platform System Time Zone: GMT+2 (GMT+3 during Daylight Saving Time)</p>
                        <p>Trading Sessions: GMT+2/+3 Monday 01:01 to Friday 23:50</p>
                        <p>Mid-Week Trading Break Sessions: GMT+2/+3 00:00 - 01:00</p>
                    </div>
                </div>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #9cc2e7;">
                <p class="cfd-title" style="color: #3a61a4;">Calculation of Trading Profit and Loss</p>
                <p style="color: #3a61a4;">Trading Profit and Loss = ( Selling Price - Buying Price ) × Contract Size × Number of Contracts</p>
                <p style="color: #3a61a4;">Example: A client buys 2 lots of XAUUSD at  2320.60, then closes the position at 2355.80 in the same day:</p>
                <p style="color: #3a61a4;">( 2355.80-2320.60 ) X 100 X 2 手= USD 7,040</p>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="border: 1px solid #5f7fb5;">
                <p class="cfd-title" style="color: #595757;">Overnight Swap Calculation</p>
                <p style="color: #595757;">Overnight Swap Calculation and Input Time: GMT+2/+3 24:00. Three days of swap are charged every Wednesday</p>
                <p style="color: #595757;">The daily swap amount is calculated in points. You can view the relevant overnight swap points in the product details on the trading terminal</p>
                <p style="color: #595757;">If the swap points are positive, it means you can receive swap; if negative, you need to pay swap</p>
                <p style="color: #595757;">Example: A client buys 2 lots of XAUUSD, then closes the position the next day. The overnight swap points for the long position are -15:</p>
                <p style="color: #595757;">100 X 0.01 X (-15) X 2 X 1 = -30 USD</p>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #344592;">
                <p class="cfd-title" style="color: #ffffff;">Limit Order</p>
                <p style="color: #ffffff;">Limit Order Validity:</p>
                <p style="color: #ffffff;">Valid until cancelled. On the first weekend of each year, <br>the system will automatically delete all unfilled new pending order instructions (Take Profit and Stop Loss orders on open positions are not affected).</p>
                <p style="color: #ffffff;">Under normal market conditions, the minimum gap between the limit order price and the market price: 20 points (0.2 cents)</p>
                <p style="color: #ffffff;">* When a price gap occurs, the trading system's automatic stop-loss execution mechanism will take precedence over the client's pending orders within the gap range</p>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #112445;">
                <p class="cfd-title" style="color: #ffffff;">Spread and Execution</p>
                <p style="color: #ffffff;">Execution Rules (including Limit Orders): Executed at Market Price</p>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #9cc2e7;">
                <p class="cfd-title" style="color: #3a61a4;">Net Open Position (NOP) Limit and Per Trade Limit</p>
                <p style="color: #3a61a4;">Each client (including all sub-accounts and all spread types) has a NOP limit of 50 standard lots per metal product</p>
                <p style="color: #3a61a4;">The number of lots per trade placing is 0.01-10 lots, with step of 0.01 lots</p>
            </div>
        `,

        "CFDIndex": `
            <div class="width-100 cfd-table-bos scrollbar-x wow animated fadeInUp animation-fill-mode-initial">
                <table class="width-100 text-center" style="max-width: 1200px;" cellpadding="0" cellspacing="0">
                    <tr>
                        <th>Index</th>
                        <th>Symbol</th>
                        <th>Contract Size</th>
                        <th>Leverage</th>
                        <th>Tick Size</th>
                        <th>Tick Value</th>
                    </tr>
                    <tr>
                        <td>German DAX40 Index</td>
                        <td>GER40</td>
                        <td>20</td>
                        <td>1%</td>
                        <td>0.1</td>
                        <td>2.0 EUR</td>
                    </tr>
                    <tr>
                        <td>Hong Kong Hang Seng Index</td>
                        <td>HK50</td>
                        <td>50</td>
                        <td>1%</td>
                        <td>0.1</td>
                        <td>5.0 HKD</td>
                    </tr>
                    <tr>
                        <td>Nikkei 225 Index</td>
                        <td>JPN225</td>
                        <td>500</td>
                        <td>1%</td>
                        <td>0.1</td>
                        <td>50.0 JPY</td>
                    </tr>
                    <tr>
                        <td>NASDAQ 100 Index</td>
                        <td>NAS100</td>
                        <td>20</td>
                        <td>1%</td>
                        <td>0.1</td>
                        <td>2.0 USD</td>
                    </tr>
                    <tr>
                        <td>S&P 500 Index</td>
                        <td>SPX500</td>
                        <td>50</td>
                        <td>1%</td>
                        <td>0.01</td>
                        <td>0.5 USD</td>
                    </tr>
                    <tr>
                        <td>FTSE 100 Index</td>
                        <td>UK100</td>
                        <td>20</td>
                        <td>1%</td>
                        <td>0.01</td>
                        <td>0.2 GBP</td>
                    </tr>
                    <tr>
                        <td>Dow Jones 30 Index</td>
                        <td>US30</td>
                        <td>10</td>
                        <td>1%</td>
                        <td>0.1</td>
                        <td>1.0 USD</td>
                    </tr>
                    <tr>
                        <td>Brent Crude Oil Spot Index</td>
                        <td>UKOIL</td>
                        <td>1,000</td>
                        <td>1%</td>
                        <td>0.001</td>
                        <td>1.0 USD</td>
                    </tr>
                    <tr>
                        <td>WTI Crude Oil Index</td>
                        <td>USOIL</td>
                        <td>1,000</td>
                        <td>1%</td>
                        <td>0.001</td>
                        <td>1.0 USD</td>
                    </tr>
                    <tr>
                        <td>FTSE China A50 Index</td>
                        <td>XINA50</td>
                        <td>20</td>
                        <td>1%</td>
                        <td>0.1</td>
                        <td>2.0 USD</td>
                    </tr>
                </table>
            </div>

            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #112445;">
                <p class="cfd-title" style="color: #ffffff;">Leverage</p>
                <p style="color: #ffffff;">CFD index products leverage ratio is standardized according to the above margin requirements, and adjustments to the trading account leverage ratio do not apply to CFD index products</p>
                <p style="color: #ffffff;">The margin for hedging is 10% of the new position, and a set of one buy and one sell order together requires 20% of the margin for a new position of 1 lot.</p>
            </div>

            <div class="cfd-table-2" style="background: #3a61a4;">
                <p class="cfd-title" style="color: #ffffff; padding: 0 15px;">Platform Time Zone and Trading Sessions</p>
                <p style="color: #ffffff; padding: 0 15px;">Platform System Time Zone: GMT+2 (GMT+3 during Daylight Saving Time)</p>
                <div class="scrollbar-x">
                    <table class="width-100 text-center" cellpadding="0" cellspacing="0">
                        <tr>
                            <td>GER40</td>
                            <td>GMT+2 /+3 Monday to Friday: 02:30 - 23:00</td>
                        </tr>
                        <tr>
                            <td>HK50</td>
                            <td>GMT+2 Monday to Friday: 03:15-06:00 / 07:00-10:30 / 11:15-17:45</td>
                        </tr>
                        <tr>
                            <td>JPN225</td>
                            <td>GMT+2/+3 Monday 01:01 - Friday 23:40, Mid-Week trading break session: 00:00-01:00</td>
                        </tr>
                        <tr>
                            <td>NAS100</td>
                            <td>GMT+2/+3 Monday 01:01 - Friday 23:40, Mid-Week trading break session: 00:00-01:00</td>
                        </tr>
                        <tr>
                            <td>SPX500</td>
                            <td>GMT+2/+3 Monday 01:01 - Friday 23:40, Mid-Week trading break session: 00:00-01:00</td>
                        </tr>
                        <tr>
                            <td>UK100</td>
                            <td>GMT+2 /+3 Monday to Friday: 02:30 - 23:00</td>
                        </tr>
                        <tr>
                            <td>US30</td>
                            <td>GMT+2/+3 Monday 01:01 - Friday 23:40, Mid-Week trading break session: 00:00-01:00</td>
                        </tr>
                        <tr>
                            <td>UKOIL</td>
                            <td>GMT+2/+3 Monday 03:00 - Friday 23:40, Mid-Week trading break session: 00:00-03:00</td>
                        </tr>
                        <tr>
                            <td>USOIL</td>
                            <td>GMT+2/+3 Monday 01:01 - Friday 23:40, Mid-Week trading break session: 00:00-01:00</td>
                        </tr>
                        <tr>
                            <td>XINA50</td>
                            <td>GMT+2 Monday to Friday: 03:00-10:30 / 11:00-22:40</td>
                        </tr>
                    </table>
                </div>
                <p style="color: #ffffff; padding: 0 15px;">*  During Daylight Saving GMT+3 time zone, the system display time for HK50 and XINA50 will be shifted forward by one hour</p>
            </div>

            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #9cc2e7;">
                <p class="cfd-title" style="color: #3a61a4;">Calculation of Trading Profit and Loss</p>
                <p style="color: #3a61a4;">Trading Profit and Loss = ( Selling Price - Buying Price ) × Contract Size × Number of Contracts</p>
                <p style="color: #3a61a4;">* The currency value for calculating the profit is based on the value per point. For example, for UK100, the profit amount is in GBP.</p>
                <p style="color: #3a61a4;">Example: A Client buys 2 lots of UK100 at  8020, then closes the position at 8080 :</p>
                <p style="color: #3a61a4;">( 8080 - 8020 ) X 10 X 2  = GBP 1,200</p>
            </div>

            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="border: 1px solid #5f7fb5;">
                <p class="cfd-title" style="color: #3a61a4;">Overnight Swap Calculation</p>
                <p style="color: #3a61a4;">Overnight Swap Calculation and Input Time: GMT+2/+3 24:00. Three days of swap are charged every Friday</p>
                <p style="color: #3a61a4;">Daily swap amount = number of lots X buy / sell swap points X value per point</p>
                <p style="color: #3a61a4;">Example: A client buys 2 lots of HK50 and close the position the next day. The buy swap points are -22 :</p>
                <p style="color: #3a61a4;">2 X -22 X HKD 5.0 = HKD -220</p>
            </div>

            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #112445;">
                <p class="cfd-title" style="color: #ffffff;">Calculation of Dividends and Other Factors Affecting CFD Index Price</p>
                <p style="color: #ffffff;">As this does not actually own the physical stocks when trading CFD Index, therefore, this is not actually entitled to distribute any dividend on the constituent stocks, issue any option or split any share. However, to ensure that there is no material impact on the index position after the constituent stocks' price rises/falls, such extra losses/profits will be automatically deposited/deducted from the account balance (depending on the direction of position).</p>
                <p style="color: #ffffff;">Adjustment in relevant trading accounts will be reflected (deposit or deduction) the day before the calculation of the ex-dividend date. For example, if the ex-dividend date of a constituent stock is March 20 and client still holds the CFD Index position after that index's designated settlement time on March 19, it will be included in the calculation list of index dividends.</p>
                <p style="color: #ffffff;">Please refer to the announcements on the website of the specific CFD index adjustment and the calculation time.</p>
            </div>

            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #344592;">
                <p class="cfd-title" style="color: #ffffff;">Limit Order</p>
                <p style="color: #ffffff;">Limit Order Validity:</p>
                <p style="color: #ffffff;">Valid until cancelled. On the first weekend of each year,<br>the system will automatically delete all unfilled new pending order instructions (Take Profit and Stop Loss orders on open positions are not affected).</p>
                <p style="color: #ffffff;">Under normal market conditions, the minimum gap between the limit order price and the market price: 20 points</p>
                <p style="color: #ffffff;">* When a price gap occurs, the trading system's automatic stop-loss execution mechanism will take precedence over the client's pending orders within the gap range.</p>
            </div>
            <div class="ppl-flex ppl-flex-mobile-1 color-ffffff wow animated fadeInUp animation-fill-mode-initial">
                <div class="cfd-content-3 ppl-flex ppl-align-center" style="background: #9cc2e7; max-width: 30%;">
                    <div>
                        <p class="cfd-title" style="color: #3a61a4;">Spread and Execution</p>
                        <p style="color: #3a61a4;">Execution Rules (including Limit Orders): Executed at Market Price</p>
                        <p style="color: #3a61a4;">Spread: Floating Spread</p>
                    </div>
                </div>
                <div class="cfd-content-3 ppl-flex ppl-align-center" style="background: #4e73b8;">
                    <div>
                        <p class="cfd-title" style="color: #ffffff;">Net Open Position (NOP) Limit and Per Trade Limit</p>
                        <p style="color: #ffffff;">The limit for each client (including all sub-accounts) is 5 standard lots, with a maximum limit of 20 lots for the CFD Hang Seng Index</p>
                        <p style="color: #ffffff;">The number of lots per trade placing is 0.01-5 lots, with step of 0.01 lots</p>
                    </div>
                </div>
            </div>
        `,

        "USEquities": `
            <div class="width-100 cfd-table-bos scrollbar-x wow animated fadeInUp animation-fill-mode-initial">
                <table class="width-100 text-center" style="max-width: 1200px;" cellpadding="0" cellspacing="0">
                    <tr>
                        <th>Stock</th>
                        <th>Symbol</th>
                        <th>Margin</th>
                        <th>Minimum Contract / Trade</th>
                        <th>Maximum NOP</th>
                    </tr>
                    <tr>
                        <td>Apple</td>
                        <td>#AAPL</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Adobe</td>
                        <td>#ADBE</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>Automatic Data Processing Inc.</td>
                        <td>#ADP</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>Amgen Inc.</td>
                        <td>#AMGN</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>Amazon</td>
                        <td>#AMZN</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>ASML Holding N.V. New York Registry Shares</td>
                        <td>#ASML</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>Broadcom Inc.</td>
                        <td>#AVGO</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>American Express</td>
                        <td>#AXP</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>Boeing</td>
                        <td>#BA</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Biogen Inc.</td>
                        <td>#BIIB</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>Berkshire Hathaway</td>
                        <td>#BRK.B</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Caterpillar</td>
                        <td>#CAT</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Charter Communications Inc.</td>
                        <td>#CHTR</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>Costco Wholesale Corporation</td>
                        <td>#COST</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>Cisco Systems</td>
                        <td>#CSCO</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>Chevron</td>
                        <td>#CVX</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>Dropbox</td>
                        <td>#DBX</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>Walt Disney</td>
                        <td>#DIS</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>eBay</td>
                        <td>#EBAY</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>Expedia</td>
                        <td>#EXPE</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>Ford Motor</td>
                        <td>#F</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>General Electric</td>
                        <td>#GE</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>Google</td>
                        <td>#GOOG</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>Goldman Sachs</td>
                        <td>#GS</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Home Depot Inc.</td>
                        <td>#HD</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>Herbalife Nutrition Ltd</td>
                        <td>#HLF</td>
                        <td>20%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>IBM</td>
                        <td>#IBM</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Intel</td>
                        <td>#INTC</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>Johnson & Johnson</td>
                        <td>#JNJ</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>JPMorgan Chase & Co</td>
                        <td>#JPM</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>Coca-Cola</td>
                        <td>#KO</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>Lockheed Martin Corporation</td>
                        <td>#LMT</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Lyft</td>
                        <td>#LYFT</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>Mastercard Incorporated</td>
                        <td>#MA</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Marriott International</td>
                        <td>#MAR</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>McDonald's</td>
                        <td>#MCD</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Meta (Facebook)</td>
                        <td>#META</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>3M</td>
                        <td>#MMM</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Merck & Company Inc.</td>
                        <td>#MRK</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Moderna</td>
                        <td>#MRNA</td>
                        <td>20%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>Morgan Stanley</td>
                        <td>#MS</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>Microsoft</td>
                        <td>#MSFT</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Netflix</td>
                        <td>#NFLX</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>Nike</td>
                        <td>#NKE</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>NVIDIA</td>
                        <td>#NVDA</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>Pepsi</td>
                        <td>#PEP</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Pfizer</td>
                        <td>#PFE</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>Procter & Gamble</td>
                        <td>#PG</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>PayPal Holdings Inc.</td>
                        <td>#PYPL</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>Starbucks</td>
                        <td>#SBUX</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>AT&T</td>
                        <td>#T</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>Target Corporation</td>
                        <td>#TGT</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Toyota Motor Corporation</td>
                        <td>#TM</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>The Travelers Companies Inc.</td>
                        <td>#TRV</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Tesla</td>
                        <td>#TSLA</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>100</td>
                    </tr>
                    <tr>
                        <td>Taiwan Semiconductor</td>
                        <td>#TSM</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>Texas Instruments</td>
                        <td>#TXN</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Visa</td>
                        <td>#V</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Verizon Communications</td>
                        <td>#VZ</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>Wells Fargo & Company</td>
                        <td>#WFC</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                    <tr>
                        <td>Walmart</td>
                        <td>#WMT</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>200</td>
                    </tr>
                    <tr>
                        <td>Wynn Resorts</td>
                        <td>#WYNN</td>
                        <td>10%</td>
                        <td>1</td>
                        <td>400</td>
                    </tr>
                    <tr>
                        <td>Exxon Mobil</td>
                        <td>#XOM</td>
                        <td>5%</td>
                        <td>1</td>
                        <td>1000</td>
                    </tr>
                </table>
            </div>

            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="border: 1px solid #5f7fb5;">
                <p class="cfd-title" style="color: #3a61a4;">Leverage</p>
                <p style="color: #595757;">The maximum leverage is 1:20, which is equivalent to 5% of the contract value</p>
                <p style="color: #595757;">The margin for hedging is 10% of the new position, and a set of one buy and one sell order together requires 20% of the margin for a new position of 1 lot</p>
                <p style="color: #595757;">CFD Equities leverage ratio is standardized according to the above margin requirements, and adjustments to the trading account leverage ratio do not apply to CFD Equities products</p>
            </div>
            <div class="ppl-flex ppl-flex-mobile-1 color-ffffff wow animated fadeInUp animation-fill-mode-initial">
                <div class="cfd-content-2 ppl-flex ppl-align-center" style="background: #112445;">
                    <div>
                        <p class="cfd-title">Platform Time Zone and Trading Sessions</p>
                        <p>Platform System Time Zone: GMT+2 (GMT+3 during Daylight Saving Time)</p>
                        <p>CFD US Equities trading sessions: Monday to Friday GMT+2/+3: 16:30-23:00</p>
                    </div>
                </div>
                <div class="cfd-content-2 ppl-flex ppl-align-center" style="background: #3a61a4;">
                    <div>
                        <p class="cfd-title">Transaction Fee</p>
                        <p>0.4% entry price of each contract, with USD10 minimum transaction fee for each round trip</p>
                        <p>Example: A client buys 20 lots of Apple's CFD Equities at  250</p>
                        <p>The fee is: 250 X 20 X 0.004 = 20 USD</p>
                        <p>*The transaction fee of CFD Equities are fixed, which is different from the transaction fee of other products.</p>
                    </div>
                </div>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #9cc2e7;">
                <p class="cfd-title" style="color: #3a61a4;">Calculation of Trading Profit and Loss</p>
                <p style="color: #3a61a4;">Trading Profit and Loss = ( Selling Price - Buying Price ) × Contract Size × Number of Contracts</p>
                <p style="color: #3a61a4;">Example: A client buys 20 lots of Apple's CFD Equities at a price of 250 and closes the position at 260</p>
                <p style="color: #3a61a4;">(260 - 250)  X 1 X 20  = USD 200</p>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="border: 1px solid #5f7fb5;">
                <p class="cfd-title" style="color: #3a61a4;">Overnight Swap Calculation</p>
                <p style="color: #595757;">Overnight Swap Calculation and Input Time: GMT+2/+3 24:00. Three days of swap are charged every Friday</p>
                <p style="color: #595757;">Swap amount per day = entry price × contract volume × buy or sell swap / 360</p>
                <p style="color: #595757;">Example: A client buys 20 lots of Apple's CFD Equities at 250, and holds the position for a day at a buying swap rate of -4.5%</p>
                <p style="color: #595757;">The swap of the day is: 250 X 20 X -4.5% / 360 =  - USD 0.63</p>
                <p style="color: #595757;">The proportion of positions held by buyers and sellers in the market is considered when calculating the overnight swap of CFD Equities. For example, when 90% of the market participants bullish on a market or a stock, the swap to be paid by the buyers is significantly different from that to be paid by the sellers. The sellers may even have swap fee receivable.</p>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #112445;">
                <p class="cfd-title" style="color: #ffffff;">Calculation of Dividends and Other Factors Affecting CFD Equities Price</p>
                <p style="color: #ffffff;">As this does not actually own the physical stocks when trading CFD, therefore this is not actually entitled to distribute any dividend on the stocks, issue any option or split any share.However, to ensure that there is no material impact on the position after the stock price rises/falls, such extra losses/profits will be automatically deposited/deducted from the account balance (depending on the direction of position).</p>
                <p style="color: #ffffff;">Adjustment in relevant trading accounts will be reflected (deposit or deduction) after the closing of the trading day immediately before the calculation date on which the issue affecting the price of the individual stock is included.For example, if the ex-dividend date of an individual stock is March 20 and client still holds the CFD position of the stock after the closing of the stock market on March 19, it will be included in the calculation list of adjusting the balance of stock dividend.</p>
                <p style="color: #ffffff;">Please refer to the announcements on the website of the specific exchange for information about the stock dividend adjustment and other issues related to the stock.</p>
            </div>
            <div class="cfd-content-1 wow animated fadeInUp animation-fill-mode-initial" style="background: #344592;">
                <p class="cfd-title" style="color: #ffffff;">Net Open Position (NOP) Limit and Per Trade Limit</p>
                <p style="color: #ffffff;">The NOP for each client (including all sub-accounts) for each CFD Equities is referenced in the above list</p>
                <p style="color: #ffffff;">The number of lots per trade placing is 1-100 lots, with step of 1 lot. It must comply with the maximum position limit requirements, which can be referenced in the above list.</p>
            </div>
            <div class="ppl-flex ppl-flex-mobile-1 color-ffffff wow animated fadeInUp animation-fill-mode-initial">
                <div class="cfd-content-3 ppl-flex ppl-align-center" style="background: #9cc2e7; max-width: 30%;">
                    <div>
                        <p class="cfd-title" style="color: #3a61a4;">Spread and Execution</p>
                        <p style="color: #3a61a4;">Execution Rules (including Limit Orders): Executed at Market Price</p>
                        <p style="color: #3a61a4;">Spread: Floating Spread</p>
                    </div>
                </div>
                <div class="cfd-content-3 ppl-flex ppl-align-center" style="background: #4e73b8;">
                    <div>
                        <p class="cfd-title" style="color: #ffffff;">Limit Order</p>
                        <p style="color: #ffffff;">All limit orders are valid only for the day or for a client-specified duration ( whichever is shorter )</p>
                        <p style="color: #ffffff;">The gap between the limit order price and the market price varies for each CFD Equities. You can view the detail data for the relevant stock on the trading platform</p>
                    </div>
                </div>
            </div>
        `,

        "footer_box": `
        <div class="footer-ltn-list">
            <div>
                <p>PPL International Limited is regulated by the Anjouan Offshore Finance Authority (AOFA) in the Autonomous Island of Anjouan, Union of Comoros. License number L15711/PPL and is registered at Hamchako, Mutsamudum The Autonomous Island of Anjouan, Union of Comoros.</p>
                <div>
                    <i class="el-icon-message mr-3px font-18"></i>
                    Email：<a href="mailto:enquiry@pplgm.com">enquiry@pplgm.com</a>
                </div>
            </div>
            <div>
                <p>PP Link Securities Co., Ltd, is regulated by the Securities and Exchange Regulator of Cambodia (SERC), registration number 00036349 and is located at Room 501, 5th Floor, Golden Tower, No.158-166, Street 215, Sangkat Veal Vong, Khan 7 Makara, Phnom Penh.</p>
                <div>
                    <i class="el-icon-message mr-3px font-18"></i>
                    Email：<a href="mailto:info@pplinksecurities.com.kh">info@pplinksecurities.com.kh</a>
                </div>
            </div>
        </div>
        `,
    }
};
