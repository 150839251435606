<template>
    <el-dialog :visible.sync="dialogVisible"
               :class="`${openObj.pscn_cover?'':'company_notice_dialog-bg-ffffff'} company_notice_dialog`"
               :append-to-body="true" :close-on-click-modal="false" :close-on-press-escape="false">
        <div class="width-100 position-relative">
            <i @click="handClose" class="close-notice el-icon-error color-ffffff font-26"></i>
            <template v-if="openObj.pscn_link">
                <a v-if="openObj.pscn_link.indexOf('http')!=-1" :href="openObj.pscn_link" @click.native="handClose" target="_blank">
                    <img :src="openObj.pscn_cover" class="d-block width-100">
                </a>
                <router-link :to="openObj.pscn_link" @click.native="handClose">
                    <img :src="openObj.pscn_cover" class="d-block width-100">
                </router-link>
            </template>
            <template v-else>
                <template v-if="openObj.pscn_cover">
                    <router-link :to="`/company-notice/detail?id=${openObj.pscn_main_id}`" @click.native="handClose">
                        <img :src="openObj.pscn_cover" class="d-block width-100">
                    </router-link>
                </template>
                <template v-else>
                    <div class="p-20px">
                        <div class="width-100 mb-15px">
                            <router-link class="font-20 color-000000 font-weight-bold" :to="`/company-notice/detail?id=${openObj.pscn_main_id}`" @click.native="handClose">
                                {{openObj.pscn_title}}
                            </router-link>
                        </div>
                        <div class="width-100" v-html="openObj.pscn_content"></div>
                    </div>
                </template>
            </template>
        </div>
    </el-dialog>
</template>

<script>
    import {mapGetters} from "vuex";
    import {getCookies, setCookies} from "@/utils/auth";

    export default {
        name: "companyNotice",
        computed:{...mapGetters(['common'])},
        data(){
            return {
                dialogVisible: false,
                openObj:{},
            }
        },
        methods:{
            handClose(){
                let that = this
                that.dialogVisible = false;
                setCookies({key:'noticeTime',value:{time:new Date()}})
            },
            showCompanyNotice(){
                let that = this
                let openObj = that.common.common.company_notice.find(item=>item.pscn_language===that.$i18n.locale)
                if (openObj && that.isWithinTimeRange(openObj.pscn_pop_start,openObj.pscn_pop_end) && that.runTime()){
                    if (openObj.pscn_cover){
                        openObj.pscn_cover = openObj.pscn_cover.indexOf('http')===-1 ? that.common.common.domain + openObj.pscn_cover : openObj.pscn_cover
                    }
                    console.log(openObj)
                    that.openObj = openObj
                    that.dialogVisible = true
                }
            },
            runTime(){
                let that = this
                let status = true
                let getTime = getCookies('noticeTime')
                if(getTime){
                    let targetDate = new Date(getTime.time); // 将字符串转换为Date对象
                    let currentDate = new Date(); // 当前时间
                    let timeDifference = currentDate.getTime() - targetDate.getTime(); // 计算时间差
                    timeDifference = timeDifference / (1000 * 60)
                    console.log(timeDifference)
                    if(timeDifference > Number(that.openObj.pscn_pop_rate)){
                        status = true
                    }else{
                        status = false
                    }
                }
                return status
            },
            isWithinTimeRange(startTime, endTime) {
                const timeObj = new Date();
                const startObj = new Date(startTime);
                const endObj = new Date(endTime);
                return timeObj >= startObj && timeObj <= endObj;
            }
        },
        mounted() {
            let that = this
            setTimeout(()=>{
                that.showCompanyNotice()
            },1000)
        },
        watch:{
            '$route'(to,from){
                let that = this
                setTimeout(()=>{
                    that.showCompanyNotice()
                },1000)
            }
        }
    }
</script>

<style>
    .company_notice_dialog .el-dialog{ width: 96%; max-width: 768px; background: transparent; border-radius: 15px;}
    .company_notice_dialog .el-dialog__body{ padding: 0;}
    .company_notice_dialog .el-dialog__header{ display: none;}
    .company_notice_dialog img{ border-radius: 15px;}
    .close-notice{ background: rgba(0,0,0,.5); border-radius: 100%; position: absolute; z-index: 9999; right: -10px; top: -10px; cursor: pointer;}
    .company_notice_dialog-bg-ffffff .el-dialog{ background: #ffffff;}
</style>
