export default {
    message: {
        "password_inconsistency":"Password inconsistency",
        "required":"Required",
        "required_selection":"Required Selection",
        "min_max_alert":"Smaller values is not greater than larger values",
        "please_enter_correct_value":"Please enter the correct value",
        "no_data":"Not Data Found",
        "least_one_name":"At least input ONE name",
        "parameter_unusual":"Parameter is unusual, please re-enter",
        "submitted_successfully":"Submitted successfully",
        "submitted_failed":"Submitted Failed",
        "operation_successfully":"Operation successfully",
        "please_uplaod_file":"Please Upload File",
        "success":"Success",
        "failed" :"Failed",
        "update_successful":"Update Failed",
        "update_failed" :"Update Successful",
        "import_success":"Import Success",
        "import_failed" :"Import Failed",
        "upload_file_success":"Upload Success",
        "upload_file_failed":"Upload Failed",
        "maximum_size_of_5MB":"file cannot be larger than 5MB",
        "EPI20001":"Approval is exist",
        "loading_failed":"Loading Failed",
        "select_price":"Please Selete offer",
        "cannot_repeat":"Cannot repeat",
        "enquiry_not_exit":"Enquiry is not exist",
        "preorder_is_exit":"The requirement has been submitted. Please do not submit it again",
        "E10006":"Error: Invalid Verification Code",
        "E10007":"Error: Invalid User Name or Password",
        "E10008":"Error: Email is not exist",
        "login_error":"Error: Invalid User Name or Password",
        "old_password_incorrect":"Old password incorrect.",
        "no_approval_permission":"No Permission",
        "please_select_file_to_upload": "Please select the file to upload",
        "upload_template_format": "Upload template is only XLS, XLSX, CSV format!",
        "upload_template_size_5mb": "Upload template size no more than 5 MB!",
        "exchange_rate_can_not_be_empty": "The exchange rate can't be empty",
        "form_submit_failure":"Form did not change, submit failure",
        "one_must_at_least_check":"One must at least check",
        "check_on_the_menu":"Check at least one item on the menu",
        "customer_selects_at_least_one_item":"The customer selects at least one item",
        "correct_address_msg":"Please enter a valid email address",
        "email_address_msg": "Please enter your email address",
        "tel_already_exist": "Tel already exist",
        "email_already_exist": "Email already exist",
        "email_cannot_be_repeated": "Email cannot be repeated",
        "tel_cannot_be_repeated": "Tel cannot be repeated",
        "name_cannot_be_repeated": "Name cannot be repeated",
        "tel_and_email_are_required": "Tel and email are required",
        "stakeholder_payee_exsits":"Payee already exists.",
        "template_is_error":"Template is error",
        "osp_company_is_exit":"Packaging companies already exist",
        "customer_is_exit":"Customer is exist",
        "not_need_to_submit":"The data is not modified, do not need to submit",
        "insufficient_balance":"Insufficient balance",
        "max_350_char":"Cannot more than 350 char(s)",
        "max_300_char":"Cannot more than 300 char(s)",
        "max_255_char":"Cannot more than 255 char(s)",
        "max_80_char":"Cannot more than 80 char(s)",
        "max_50_char":"Cannot more than 50 char(s)",
        "max_20_char":"Cannot more than 20 char(s)",
        "required_while_save_without_details":"Required while save without details",
        "password_minimum_6_characters":"Password minimum of 6 characters.",
        "pls_8_char":"Please use a strong password. Minimum of 8 characters.",
        "pwd_too_low":"Password strength is too low.",

        "message_1": "Please accept our Terms and Conditions and Privacy Policy before proceeding.",
        "message_2": "Mobile phone number does not exist. Please login with another mobile phone number or go to register page.",
        "message_3_1": "Please use a valid mobile number,",
        "message_3_2": "digits are required.",
        "message_4": "Successful change Password.",
        "message_5": "You have entered invalid code multiple times. Please try again later.",
        "message_6": "Successfully re-sent verification code.",
        "message_7": "Please select a picture to upload.",
        "message_8": "The image type must be.GIF,jpeg,jpg,png,bmp",
        "message_9": "Successfully copied to clipboard!",
        "message_10": "Cannot same as user id",
        "message_11": "The reset password link is sent.",
        "message_12": "Email does not exist.",
        "message_13": "Login Success.",
        "message_14": "Reset Password Success.",
        "message_15": "Update Success.",
        "message_16": "Social media name required",
        "message_17": "Create Success.",
        "message_18":"你需要達到任務要求等級後才可申請參與。詳情請查閱「我的資料 > 等級」。",
    }
};
