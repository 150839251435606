
<template>
    <div id="header_bgs" class="width-100" :style='`background-image: url("${topBg}");`'>
        <div class="header-box">
            <div :class="`header-content-box animated ${pageWidth > 900 ? 'fadeInDown animation-fill-mode-initial' : ''}` ">
                <router-link to="/" class="ppl-header-logo">
                    <img :src="hostUrl+common.common.common.site_logo.top_logo" alt="">
                </router-link>
                <div class="header-right-nav">
                    <template v-for="(item,index) in headerTopNav">
                        <router-link v-if="item.link && item.link.indexOf('http')===-1" :to="item.link" :key="index+'i'">
                            <img :src="hostUrl+item.images" alt="" :id="item.id">
                            <p :id="item.id">{{item.title}}</p>
                        </router-link>
                        <a v-if="item.link && item.link.indexOf('http')!==-1" :href="item.link" :key="index+'i'" target="_blank">
                            <img :src="hostUrl+item.images" alt="" :id="item.id">
                            <p :id="item.id">{{item.title}}</p>
                        </a>

                        <a v-if="!item.link" v-loading="item.id?loading:false" href="javascript:" :id="item.id" :key="index+'j'">
                            <img :src="hostUrl+item.images" alt="" :id="item.id">
                            <p :id="item.id">{{item.title}}</p>
                        </a>
                    </template>

<!--                    <a v-loading="item.id?loading:false" v-for="(item,index) in headerTopNav" :href="item.url||'javascript:'" :id="item.id" :key="index">-->
<!--                        <img :src="item.images" alt="" :id="item.id">-->
<!--                        <p :id="item.id">{{$utils.translate(item.label)}}</p>-->
<!--                    </a>-->
                    <div class="header-lang-box">
                        <img src="../assets/images/icon/i5.png" alt="">
                        <div class="width-100 text-center font-11">
<!--                            <a @click="handleSetLanguage('en')" href="javascript:">ENG </a>|-->
<!--                            <a @click="handleSetLanguage('zh')" href="javascript:">简体</a>-->
                            <template v-if="langList.length<3">
                                <template v-for="(item,index) in langList">
                                    <a :key="index" @click="handleSetLanguage(item.pscl_language)" href="javascript:">{{item.pscl_defalut_language}}</a>
                                    <template v-if="index!==langList.length-1">
                                        |
                                    </template>
                                </template>
                            </template>
                            <template v-else>
                                <el-dropdown trigger="click" @command="handleSetLanguage">
                                <span class="el-dropdown-link color-ffffff cursor-pointer">
                                    {{checkLangName($i18n.locale)}}<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                    <el-dropdown-menu slot="dropdown">
                                        <el-dropdown-item v-for="(item,index) in langList" :command="item.pscl_language" :key="index">{{item.pscl_defalut_language}}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>

                        </div>
                    </div>
                </div>
                <a @click="openMenu = true" class="menu-icon-box" href="javascript:">
                    <i class="fa fa-bars"></i>
                </a>
            </div>


            <div :class="`header-nav-box ${openMenu && pageWidth <= 900?'open-header-nav-box':''}`">
                <nav class="header-nav-list animated fadeInUp animation-fill-mode-initial">
                    <ul>
                        <li class="nav-mobile text-right mb-20px menu-mobile-box">
                            <i @click="openMenu = false" class="el-icon-close cursor-pointer mr-15px"></i>
                        </li>

                        <li v-for="(item,index) in headerNavList" :key="index"
                            :class="`${hoverStatus?'no-hover':''} ${openNav == index ? 'open-nav-mobile':''}`">
                            <router-link v-if="item.psmc_link" :to="item.psmc_link" @click.native="clickLink" class="nav-parent-box">{{item.psmn_name}}</router-link>
                            <a v-if="!item.psmc_link" href="javascript:" class="nav-parent-box">{{item.psmn_name}}</a>

                            <i v-if="item.children.length" @click="handOpenNav(index)"
                               :class="`nav-mobile cursor-pointer font-14 color-000000 ${openNav == index ? 'el-icon-minus' : 'el-icon-plus'}`"></i>

                            <div class="nav-children-parent-box">
                                <ul v-if="item.children.length" class="nav-children-box">
                                    <li v-for="(cItem,cIndex) in item.children" :key="cIndex">
                                        <router-link v-if="cItem.psmc_link" :to="cItem.psmc_link" @click.native="clickLink">{{cItem.psmn_name}}</router-link>
                                        <a v-else href="javascript:">{{cItem.psmn_name}}</a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <div class="width-100 text-center nav-mobile mt-15px">
<!--                        <a @click="handleSetLanguage('en')" class="font-14 color-B05F0B mr-5px" href="javascript:">ENG </a>-->
<!--                        <a @click="handleSetLanguage('zh')" class="font-14 color-B05F0B ml-5px" href="javascript:">简体</a>-->

                        <template v-for="(item,index) in langList">
                            <a :key="index" @click="handleSetLanguage(item.pscl_language)" class="font-14 color-B05F0B mr-5px" href="javascript:">{{item.pscl_defalut_language}} </a>
                        </template>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import {Loading} from "element-ui";

    export default {
        name: "topHeader",
        computed:{...mapGetters(['common'])},
        props:{
            navList: {
                type: Array,
                default:()=>[]
            }
        },
        data(){
            return{
                topBg:require('@/assets/images/b3.jpg'),
                pageWidth: 1920,
                hoverStatus: false,
                openMenu:false,
                openNav:null,
                headerTopNav:[
                    {label: 'mt5_web', url: '/mt5-web', img: '../assets/images/icon/i1.png'},///mt5-web
                    {label: 'live_chat', url: '', img: '../assets/images/icon/i2.png', id:'Inquire'},
                    {label: 'register_now', url: 'https://td.pplgm.com/auth/register', img: '../assets/images/icon/i3.png'},
                    {label: 'sign_in', url: 'https://td.pplgm.com/auth/login', img: '../assets/images/icon/i4.png'},
                ],
                headerNavList:[],
                loading:true,
                hostUrl:'',
                langList:[],
            }
        },
        created() {
            let that = this;
            that.hostUrl = that.common.common.domain+'/'
            that.headerNavList = that.navList
            document.documentElement.lang = that.$i18n.locale == 'zh-CN' ? 'zh-hans' : 'en';
            that.langList = that.common.common.language

            let tempTopNav = that.common.common.common.head_navigation ? that.common.common.common.head_navigation[that.$i18n.locale] : []
            tempTopNav.list.sort((a, b) => a.sort - b.sort)
            that.headerTopNav = tempTopNav.list
        },
        methods:{
            checkLangName(val){
                let that = this;
                let langObj = that.langList.find(item=>val===item.pscl_language)
                return langObj.pscl_defalut_language
            },
            handOpenNav(index){
                let that = this;
                that.openNav = that.openNav != index ? index : null
            },
            clickLink(){
                let that = this;
                if (that.pageWidth > 900){
                    that.hoverStatus = true;
                    setTimeout(()=>{
                        that.hoverStatus = false
                    },500)
                }
                else {
                    that.openMenu = false;
                }
            },
            handleSetLanguage(lang) {
                let that = this;
                if (that.$i18n.locale !== lang){
                    that.openMenu = false;
                    // document.documentElement.lang = lang == 'en' ? lang : 'zh-hans';

                    Loading.service({
                        lock: true,
                        spinner: 'fa fa-spinner fa-pulse font-32 color-000000'
                    });
                    setTimeout(()=>{
                        that.$i18n.locale = lang;
                        that.$store.dispatch('app/setLanguage', lang);
                        location.reload();
                    },500)
                }
            },
            getResize(){
                this.pageWidth = window.innerWidth
            },
            getLiveChat(){
                let that = this;
                that.headerTopNav.forEach(item=>{
                    if (item.js_code){
                        let executeCode = new Function(item.js_code);
                        executeCode();
                        if (item.js_code.indexOf('window.wcSettings')!==-1){
                            window.onCinnoxReady = (cinnox) => {
                                // console.log(cinnox,'----------------cinnox')
                                that.$nextTick(()=>{
                                    cinnox.widget.setWidgetDisplay(false);
                                    that.loading = false
                                })
                            }
                        }
                    }
                })
                // window.wcSettings = { serviceName: 'firstbullion.cinnox.com', ctaTags:{ 'Inquire': { tagId: '903add6f-0e02-4074-9bdf-b93e66211194', contactMethod: 'IM' } }, appliedAppearanceTheme: { name: 'CINNOX', index: 0, id: 'c269c960-1e32-45bd-bd8c-1aeee8766744' } }; !function(e,t,c){var n,s=e.getElementsByTagName(t)[0];e.getElementById(c)||(n=e.createElement(t),n.id=c,n.defer=!0,n.src="https://cxwc.cx.cinnox.com/cxwc/cxwc.js",n.charset="UTF-8",n.onload=function(){ window.inject()},s.parentNode.insertBefore(n,s))}(document,"script", "wc-dom-id");
                // window.onCinnoxReady = (cinnox) => {
                //     // console.log(cinnox,'----------------cinnox')
                //     that.$nextTick(()=>{
                //         cinnox.widget.setWidgetDisplay(false);
                //         that.loading = false
                //     })
                // }
            }
        },
        mounted() {
            let that = this;
            that.getResize()
            window.addEventListener('resize', that.getResize);
            that.getLiveChat()
        },
        beforeDestroy() {
            let that = this;
            window.removeEventListener('resize', that.getResize);
        },
        watch:{
            navList(to,from){
                this.headerNavList = to
            }
        }
    }
</script>

<style scoped>
    #header_bgs{ background-position: top; background-repeat: no-repeat;background-size: cover;}
</style>
