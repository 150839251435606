import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * Sub-menu only appear when children.length>=1
 **/

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin', 'editor']   Visible for these roles only
    permissions: ['view menu zip', 'manage user'] Visible for these permissions only
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if true, the page will no be cached(default is false)
    breadcrumb: false            if false, the item will hidden in breadcrumb (default is true)
    affix: true                  if true, the tag will affix in the tags-view
    noTags                       if true, no show in the tags-view
 *   hidden: Boolean | in menu show
 *   auth: true||false||undefined （undefined||true=>need login）
 *   iconType: true||false||undefined （undefined||false=>img icon）
  }
 **/
/* Router for modules */

import pageRoutes from "@/router/modules/page";
import cfdRoutes from "@/router/modules/cfd";
import aboutUsRoutes from "@/router/modules/about-us";
import marketInsightsRoutes from "@/router/modules/market-insights";
import groupNewsRoutes from "@/router/modules/group-news";
import goldProductsRoutes from "@/router/modules/gold-products";

export const constantRoutes = [

    ...pageRoutes,
    ...cfdRoutes,
    ...aboutUsRoutes,
    ...goldProductsRoutes,
    ...marketInsightsRoutes,
    ...groupNewsRoutes,

    {
        path: '/',
        component: Layout,
        children: [
            {
                path: '404',
                component: () => import('@/views/ErrorPage/404'),
                name: 'error404Page',
                hidden: true,
                meta: { auth: false, all:true},
            },
        ],
    },
    { path: '*', redirect: '/404', hidden: true},
    {
        path: '/401',
        component: () => import('@/views/ErrorPage/401'),
        hidden: true,
        meta:{auth:false, all:true}
    },
];

export const asyncRoutes = [];

const createRouter = () => new Router({
    mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    base: process.env.VUE_APP_MIX_PATH,
    routes: constantRoutes,
});

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

export default router;

