import request from '@/utils/request';

/**
 * 公共数据
 */
export function getCommon() {
    return request({
        url: '/common',
        method: 'get',
    });
}


export function smsSendOtp(data) {
    return request({
        url: '/sms/send/otp',
        method: 'post',
        data: data,
        isLogged: true,
    });
}
export function verifyOtp(data) {
    data.loading = true
    return request({
        url: '/sms/verify/otp',
        method: 'post',
        data: data,
        isLogged: true,
        loading:false
    });
}
/*
* field
* value
* 存在 0  不存在 1
*/
export function check(data) {
    return request({
        url: '/check',
        method: 'post',
        data: data,
        loading:false,
        isLogged: true,
    });
}
/*
* images upload
*/
export function imageUpload(data) {
    return request({
        url: '/image/upload',
        method: 'post',
        data: data,
        isLogged: true,
    });
}


/*
* setting
*/

export function systemSet(data) {
    return request({
        url: '/system/set',
        method: 'post',
        data: data,
    });
}
export function getSystem() {
    return request({
        url: '/system/common',
        method: 'get',
    });
}
export function testEmailSystem(data) {
    return request({
        url: '/system/test-email',
        method: 'post',
        data: data,
    });
}
export function setEmailSystem(data) {
    return request({
        url: '/system/set-email',
        method: 'post',
        data: data,
    });
}
export function setCompanySystem(data) {
    return request({
        url: '/system/company',
        method: 'post',
        data: data,
    });
}
export function setDeletePasswordSystem(data) {
    return request({
        url: '/system/delete-password',
        method: 'post',
        data: data,
    });
}
export function checkDeletePassword(data) {
    return request({
        url: '/system/check-delete-password',
        method: 'post',
        data: data,
    });
}


/**
 * 获取操作日志
 * 参数如下
 * @param group {所属分组，如 用户为'user'}
 * @param id {具体对象id}
 */
export function operateLog(query) {
    return request({
        url: '/log/basic-object-log',
        method: 'get',
        params: query,
    });
}

/**
 * 获取验证码
 */
export function yzm() {
    return request({
        url: '/auth/get-yzm',
        method: 'get',
        isLogged: true,
    });
}

/**
 * 忘记密码
 * @param data {email|code}
 */
export function retrievePassword(data) {
    return request({
        url: '/auth/retrieve-password',
        method: 'post',
        data: data,
        isLogged: true,
    });
}

/**
 * 检查邮箱
 * @param query {email}
 */
export function checkEmail(query) {
    return request({
        url: '/auth/check-email',
        method: 'get',
        params: query,
    });
}

/**
 * 修改密码--通过邮件链接进来
 * @param data {email|password}
 */
export function changePassword(data) {
    return request({
        url: '/change-password',
        method: 'post',
        data: data,
        isLogged: true,
    });
}

/**
 * 修改密码--登录进系统后修改
 * @param data {before_password|after_password}
 */
export function updatePassword(data) {
    return request({
        url: '/user/update-password',
        method: 'post',
        data: data,
    });
}

/**
 * 创建附件
 * @param data {文件}
 */
export function attachmentCreate(data) {
    return request({
        url: '/attachment/create',
        method: 'post',
        data: data,
    });
}

/**
 * 删除附件
 * @param data {paths = []}
 */
export function attachmentDelete(data) {
    return request({
        url: '/attachment/delete',
        method: 'post',
        data: data,
    });
}

/**
 * 转化为base64编码
 * @param data {file(文件)}
 */
export function attachmentChangeToBase64Code(data) {
    return request({
        url: '/attachment/change-to-base64-code',
        method: 'post',
        data: data,
    });
}

/**
 * 关联附件, ****编辑未文件接口、不与创建接口共用****
 * @param data {file(文件)，related，id}
 */
export function attachmentRelated(data) {
    return request({
        url: '/attachment/related',
        method: 'post',
        data: data,
    });
}

/**
 * 公共上传附件
 * @param api {api}
 * @param data {file}
 */
export function importFile(api,data,params) {
    if (api){
        return request({
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            url: api,
            method: 'post',
            data: data,
            params:params
        });
    }else{
        this.$message.error('import api is required')
    }
}

/**
 * 关联附件, ****编辑未文件接口、不与创建接口共用****
 * @param data {file(文件)，related，id}
 */
export function autoCompleteData(data) {
    return request({
        url: '/common/auto-complete-data',
        method: 'post',
        data: data,
    });
}

