import Layout from "@/layout/index";

const marketInsightsRoutes = [
    {
        path: '/economic-calendar',
        component: Layout,
        children: [
            {
                path: '/economic-calendar',
                component: () => import('@/views/MarketInsights/EconomicCalendar'),
                name: 'EconomicCalendar',
                meta: { auth: false,},
            },
        ],
    },
    {
        path: '/expert-analysis',
        component: Layout,
        children: [
            {
                path: '/expert-analysis',
                component: () => import('@/views/MarketInsights/ExpertAnalysis'),
                name: 'ExpertAnalysis',
                meta: { auth: false,},
            },
        ],
    },
    {
        path: '/expert-analysis/detail',
        component: Layout,
        children: [
            {
                path: '/expert-analysis/detail',
                component: () => import('@/views/MarketInsights/ExpertAnalysisDetail'),
                name: 'ExpertAnalysisDetail',
                meta: { auth: false,},
            },
        ],
    },
]


export default marketInsightsRoutes;
