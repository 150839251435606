import {setCookies} from "@/utils/auth";
import { Base64 } from "js-base64";
import store from '@/store';
import Vue from 'vue'
import i18n from "@/lang";
Vue.use(i18n)

let salting = ''
/**
 * 解密
 */
const decodeString = (name) => {

    //let decodeName = Base64.decode(unescape(name.replace(/AADDMMIINN/g, 'B'))) || ''
    let decodeName = Base64.decode(unescape(name)) || ''
    if (decodeName && decodeName.split && decodeName.split(salting) && decodeName.split(salting)[0]) {
        let Obj = decodeName.split(salting)[0];
        Obj = Obj.indexOf('{') != -1 ? JSON.parse(Obj) : Obj;
        return Obj
    } else {
        return ''
    }
}

const getUrlQuery = (name) => {
    let tempUrl = name || window.location.href
    let url = tempUrl.indexOf('?')!=-1 ? tempUrl.match(/\?(\S*)/)[1]:'';

    console.log(url)
    return url?decodeString(url):'';
}

const isErrorQuery = () => {
    setTimeout(()=>{
        let isError= document.getElementsByClassName("is-error");
        if (isError){
            if (isError[0].querySelector('input')){
                isError[0].querySelector('input').focus()
            }else if (isError[0].querySelector('textarea')){
                isError[0].querySelector('textarea').focus()
            }
        }
    },100);
}

const getUrlParams = (url) =>{
    let parames = {}; // 定义参数对象
    if (url){
        let pattern = /(\w+)=(\w+)/ig; //定义正则表达式
        url.replace(pattern, ($, $1, $2) => {
            parames[$1] = $2;
        });
    }
    return parames;
}

const priceFloat2 = (val) => {
    let tempVal = 0;
    if (val){
        tempVal = val.replace(/[^\d.]/g, '').replace(/\.{2,}/g, '.').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3').replace(/^\./g, '')
    }
    return tempVal;
}

const numberFloat2 = (val) => {
    let tempVal = 0;
    if (val){
        tempVal = Number(val).toFixed(2)
    }
    return tempVal;
}

const compareDateTime = (cDate,cTime) => {

    if(cTime=='00:00' || !cTime) {
        cTime = '23:59:59';
    } else {
        cTime+=':59';
    }

    var odateTime1 = new Date(cDate+' '+cTime);
    var odateTime2 = new Date();

    if(odateTime1.getTime() > odateTime2.getTime()) {
        return true;
    } else {
        return false;
    }
}

// 价格格式化
function numberFormat(number, decimals, dec_point, thousands_sep) {
    /*
    * 参数说明：
    * number：要格式化的数字
    * decimals：保留几位小数
    * dec_point：小数点符号
    * thousands_sep：千分位符号
    * */
    number = (number + '').replace(/[^0-9+-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.ceil(n * k) / k;
        };

    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    var re = /(-?\d+)(\d{3})/;
    while (re.test(s[0])) {
        s[0] = s[0].replace(re, "$1" + sep + "$2");
    }

    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

/*获取文件名 包括后缀名*/
const getFileNameSuffix = (name) =>{
    let fileName = ''
    if (name){
        name = name.attachment_path || name
        fileName = name.substr(name.lastIndexOf('/')+1)
    }
    return fileName;
}

/*文件拼接域名*/
const hostUrl = process.env.VUE_APP_FILE_URL
const getHostFile = (file) =>{
    if (file && file.indexOf('http') == -1){
        file = hostUrl + file
    }
    return file;
}

/*获取页面横幅*/
const getPageBanner = (url) =>{
    // console.log(url)
    let pages = store.getters.common.pages || []
    let file = ''
    if (url){
        pages.forEach(item=>{
            if (item.ospl_url == url){
                file = item.ospl_banner.indexOf('http') == -1 ? hostUrl + item.ospl_banner : item.ospl_banner
            }
        })
    }
    return file
}

const getDefaultLanguage = ()=>{
    let langList = store.getters.common.common.language || []
    let obj = langList.find(item => {return item.pscl_translate_language})
    return obj.pscl_language
}

const translate = (key) =>{
    let nowLang = i18n.locale
    let defLang = getDefaultLanguage()
    let translateList = store.getters.common.common.common.translation_dictionary[nowLang] || store.getters.common.common.common.translation_dictionary[defLang]
    let translateObj = translateList.list.find(item => item.mapping_key == key)
    return translateObj?translateObj.text:'-'
}

const getPageTitle = (url) =>{
    let menuList = store.getters.common.menu
    let pageTitle = ''
    if (url){
        let detailUrl = "/"+url.split("/")[1]
        menuList.forEach(item=>{
            let menuObj = item.children.find(item=>(item.psmc_link == url || item.psmc_link == detailUrl))
            if (menuObj){
                pageTitle = menuObj.psmn_title
            }
        })
    }
    return pageTitle
}

const getPageBg = (url) =>{
    let menuList = store.getters.common.menu
    let pageBg = ''
    if (url){
        let hostUrl = store.getters.common.common.domain+'/'
        let detailUrl = "/"+url.split("/")[1]
        menuList.forEach(item=>{
            let menuObj = item.children.find(item=>(item.psmc_link == url || item.psmc_link == detailUrl))
            if (menuObj){
                pageBg = hostUrl+menuObj.psmc_banner
            }
        })
    }
    return pageBg
}

export default {
    isErrorQuery,
    getUrlParams,
    priceFloat2,
    numberFloat2,
    compareDateTime,
    numberFormat,
    decodeString,
    getUrlQuery,
    getFileNameSuffix,
    getHostFile,
    getPageBanner,
    getDefaultLanguage,
    translate,
    getPageTitle,
    getPageBg,
}
