const global_ = {
    shicai:'時菜',
    tangshui:'湯水',
    giftAndRemarks:'贈送和備註',
    pickerOptionsOld18:{
        disabledDate(time) {
            let year18 = global_.getTimes(18,true)
            return time.getTime() > new Date(year18).getTime()
        },
    },
    getTimes(reduce,day) {
        let yy = new Date().getFullYear();
        let mm = new Date().getMonth() + 1;
        if (mm<10){
            mm = '0'+mm
        }
        let dd = new Date().getDate();
        if (dd<10){
            dd = '0'+dd
        }
        let hh = new Date().getHours();
        let mf = new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes();
        let ss = new Date().getSeconds() < 10 ? "0" + new Date().getSeconds() : new Date().getSeconds();
        let tempTime = ''
        if (reduce){
            if (day){
                tempTime = yy-reduce + "-" + mm + "-" + dd
                // tempTime = mm + "-" + dd + "-" + yy-reduce
            }else{
                tempTime = yy-reduce + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss
                // tempTime = mm + "-" + dd + "-" + yy-reduce + " " + hh + ":" + mf + ":" + ss
            }
        }else{
            tempTime = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss
            // tempTime = mm + "-" + dd + "-" + yy-reduce + " " + hh + ":" + mf + ":" + ss
        }
        return tempTime
    },
    // 设置选择今天以及今天之后的日期
    pickerOptions: {
        disabledDate(time) {
            return time.getTime() > Date.now();
        }
    },
    // 设置选择今天之后的日期（不能选择当天时间）
    pickerNoTodayOptions: {
        disabledDate(time) {
            return time.getTime() < Date.now();
        }
    },
}
export default{
    global_
}
