import Layout from "@/layout/index";

const goldProductsRoutes = [
    {
        path: '/first-gold-sole-agent',
        component: Layout,
        children: [
            {
                path: '/first-gold-sole-agent',
                component: () => import('@/views/GoldProducts/FirstGoldSoleAgent'),
                name: 'FirstGoldSoleAgent',
                meta: { auth: false,},
            },
        ],
    },
]


export default goldProductsRoutes;
