<template>
    <div class="width-100">
        <div :class="`page-content ${$i18n.locale === 'zh-CN' ? '' : 'lang-en'}`">

            <top-header :nav-list="navList"></top-header>

            <keep-alive :include="cachedViews">
                <router-view :key="key" />
            </keep-alive>

            <bottom-footer :nav-list="navList"></bottom-footer>

        </div>
        <company-notice></company-notice>

        <el-backtop class="color-6f7070" style="z-index: 99999"></el-backtop>

        <loading-show></loading-show>
    </div>
</template>

<script>
import {WOW} from "wowjs";
    import {mapGetters} from "vuex";
    import LoadingShow from "@/components/LoadingShow";
    import topHeader from '@/layout/topHeader';
    import bottomFooter from "@/layout/bottomFooter";
    import companyNotice from "@/layout/companyNotice";

export default {
        name: "LayoutIndex",
        components:{ LoadingShow, topHeader, bottomFooter, companyNotice },
        computed: {
            ...mapGetters(['sidebar','common']),
            cachedViews() {
                return this.$store.state.tagsView.cachedViews;
            },
            key() {
                return this.$route.fullPath;
            },
            isCollapse() {
                return !this.sidebar.opened;
            },
        },
        data(){
            return{
                // navList:[
                //     {
                //         label: 'nav1', url: '/ppl-international',
                //         children: [
                //             {label: 'nav1_1', url: '/ppl-international',},
                //             {label: 'nav1_2', url: '/regulatory-compliance',},
                //         ]
                //     },
                //     {
                //         label: 'nav2', url: '',
                //         children: [
                //             {label: 'nav2_1', url: '',},
                //             {label: 'nav2_2', url: '',},
                //             // {label: 'nav2_3', url: '',},
                //         ]
                //     },
                //     {
                //         label: 'nav3', url: '/cfd/forex-pairs',
                //         children: [
                //             {label: 'nav3_1', url: '/cfd/forex-pairs',},
                //             {label: 'nav3_2', url: '/cfd/bullion',},
                //             {label: 'nav3_3', url: '/cfd/index',},
                //             {label: 'nav3_4', url: '/cfd/use-quities',},
                //             // {label: 'nav3_5', url: '',},/*/cfd/cryptocurrency*/
                //         ]
                //     },
                //     {
                //         label: 'nav4', url: '',
                //         children: [
                //             {label: 'nav4_1', url: '',},
                //         ]
                //     },
                //     {
                //         label: 'nav5', url: '',
                //         children: [
                //             {label: 'nav5_1', url: '',},
                //             {label: 'nav5_2', url: '',},
                //         ]
                //     },
                //     {
                //         label: 'nav6', url: '',
                //         children: [
                //             {label: 'nav6_1', url: '',},
                //             {label: 'nav6_2', url: '',},
                //         ]
                //     },
                //     {
                //         label: 'nav7', url: '/contact-us',
                //         children: [
                //             {label: 'nav7_1', url: '/contact-us',},
                //             {label: 'nav7_2', url: '',},
                //         ]
                //     },
                // ],
                navList:[
                    {
                        label: 'nav1', url: '/ppl-international',
                        children: [
                            {label: 'nav1_1', url: '/ppl-international',},
                            {label: 'nav1_2', url: '/regulatory-compliance',},
                        ]
                    },
                    {
                        label: 'nav2', url: '/group-events',
                        children: [
                            {label: 'nav2_1', url: '/group-events',},
                            {label: 'nav2_2', url: '/latest-offers',},
                            // {label: 'nav2_3', url: '',},
                        ]
                    },
                    {
                        label: 'nav3', url: '/cfd/forex-pairs',
                        children: [
                            {label: 'nav3_1', url: '/cfd/forex-pairs',},
                            {label: 'nav3_2', url: '/cfd/bullion',},
                            {label: 'nav3_3', url: '/cfd/index',},
                            {label: 'nav3_4', url: '/cfd/use-quities',},
                            // {label: 'nav3_5', url: '',},/*/cfd/cryptocurrency*/
                        ]
                    },
                    {
                        label: 'nav4', url: '/first-gold-sole-agent',
                        children: [
                            {label: 'nav4_1', url: '/first-gold-sole-agent',},
                        ]
                    },
                    {
                        label: 'nav5', url: '/economic-calendar',
                        children: [
                            {label: 'nav5_1', url: '/economic-calendar',},
                            {label: 'nav5_2', url: '/expert-analysis',},
                        ]
                    },
                    {
                        label: 'nav6', url: '/metatrader5',
                        children: [
                            {label: 'nav6_1', url: '/metatrader5',},
                            {label: 'nav6_2', url: '/mt5-web',},
                        ]
                    },
                    {
                        label: 'nav7', url: '/contact-us',
                        children: [
                            {label: 'nav7_1', url: '/contact-us',},
                            {label: 'nav7_2', url: '/faq',},
                        ]
                    },
                ],
                hostUrl:''
            }
        },
        created() {
            let that = this;
            that.hostUrl = that.common.common.domain+'/'
            that.checkList();
            that.getInnerWidth()
            // console.log(that.common.menu)
            let tempList = that.common.menu
            tempList.forEach(item=>{
                item.children.forEach((cItem,index)=>{
                    if (index == 0){
                        item.psmc_link = cItem.psmc_link
                    }
                })
                if (item.psmc_id == 6){
                    item.children.push({
                        psmc_banner: "/api_attachments/2024/09/file.png240927113105.png",
                        psmc_link: "/ezqt",
                        psmn_name: "EZQT",
                        psmn_title: "EZQT",
                    })
                }
            })
            that.navList = tempList

            // console.log(that.$route,'that.$route')
        },
        methods:{
            checkList(){
                let that = this;
                let lang = that.$i18n.locale
                let tempList = that.common.common.common.metatrader5[lang] || that.common.common.common.metatrader5[that.$utils.getDefaultLanguage()]
                tempList.list.sort((a, b) => a.sort - b.sort);
                that.dataList = tempList.list
            },
            getInnerWidth(){
                let that = this;
                let defWidth = 1920
                let mWidth = window.innerWidth
                if (mWidth > defWidth){
                    let bodyWidth = Number( ( ((mWidth - defWidth) / defWidth).toFixed(4) ) ) * 100 + 100
                    document.getElementById('ppl-body').style.zoom = bodyWidth + '%'
                }else{
                    document.getElementById('ppl-body').style.zoom = '100%'
                }
            },
        },
        mounted() {
            let that = this;
            window.addEventListener('resize', () => {
                that.getInnerWidth()
            })
            let wow = new WOW({
                boxClass: 'wow',
                animateClass: 'animated',
                offset: 0,
                mobile: true,
                live: true
            });
            wow.init();
        },
    }
</script>

<style>
</style>
