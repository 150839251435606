import router from './router';
import store from './store';
import {Loading, Message} from 'element-ui';
import {isLogged, setCookies, setLogged} from '@/utils/auth';
import getPageTitle from '@/utils/get-page-title';
import {getCommon} from "@/api/common";


router.beforeEach(async (to, from, next) => {
    // set page title
    // document.title = getPageTitle(to.meta.title);

    // const isUserLogged = isLogged();

    // let userInfo = store.getters.userInfo
    // if (userInfo && Object.keys(userInfo).length){
    //     setCookies({key:'userInfo',value:userInfo})
    //     setLogged(isUserLogged);
    // }

    let common = store.getters.common;
    // console.log(common)
    if (!common.banner.length || !common.menu.length || !common.common || !Object.keys(common.common).length){

        let loading = Loading.service({
            lock: true,
            spinner: 'fa fa-spinner fa-pulse font-32 color-000000'
        });
        await store.dispatch('common/getCommon')
        await store.dispatch('common/getBannerList')
        await store.dispatch('common/getMenuList')
        loading.close()
    }


    next();
});

router.afterEach(() => {});
