import Cookies from 'js-cookie';

const TokenKey = 'isLogged';

export function isLogged() {
    let token = Cookies.get(TokenKey)
    token = token && token != 'undefined' ? token : ''
    return token;
}

export function setLogged(isToken) {
    let seconds = 3600;// 设置过期时间 | 秒 | seconds 秒后无操作，将重新登录
    let expires = new Date(new Date() * 1 + seconds * 1000)
    let token = isToken == 1 ? isLogged() : isToken
    return Cookies.set(TokenKey, token, { expires: expires });
}

export function removeToken() {
    return Cookies.remove(TokenKey);
}

export function getCookies(key) {
    let getInfo = key ? Cookies.get(key) : ''
    return getInfo ? JSON.parse(getInfo) : '';
}

export function setCookies(Obj) {
    if (Obj.key){
        let seconds = Obj.seconds || 3600;
        let expires = new Date(new Date() * 1 + seconds * 1000)
        Obj.value.expires = expires
        let value = JSON.stringify(Obj.value)

        if (Obj.seconds == false){
            return Cookies.set(Obj.key, value||'');
        }else{

            return Cookies.set(Obj.key, value||'', { expires: expires });
        }
    }
}

export function removeCookies(key) {
    return key ? Cookies.remove(key) : '';
}

export function loadingFns(type) {
    let loadingId = document.getElementById('loading-box')
    if (loadingId){
        if (!type){
            loadingId.style.display = 'flex'
            setTimeout(()=>{
                loadingId.style.display = 'none'
            },500)
        }
        else if (type == 'flex'){
            loadingId.style.display = 'flex'
        }
        else if (type == 'none'){
            // loadingId.style.display = 'none'
            setTimeout(()=>{
                loadingId.style.display = 'none'
            },200)
        }
    }
}
