import Layout from "@/layout/index";

const cfdRoutes = [
    {
        path: '/cfd/forex-pairs',
        component: Layout,
        children: [
            {
                path: '/cfd/forex-pairs',
                component: () => import('@/views/CFD/ForexPairs'),
                name: 'ForexPairs',
                meta: { auth: false,},
            },
        ],
    },
    {
        path: '/cfd/bullion',
        component: Layout,
        children: [
            {
                path: '/cfd/bullion',
                component: () => import('@/views/CFD/Bullion'),
                name: 'CFDBullion',
                meta: { auth: false,},
            },
        ],
    },
    {
        path: '/cfd/index',
        component: Layout,
        children: [
            {
                path: '/cfd/index',
                component: () => import('@/views/CFD/CFDIndex'),
                name: 'CFDIndex',
                meta: { auth: false,},
            },
        ],
    },
    {
        path: '/cfd/use-quities',
        component: Layout,
        children: [
            {
                path: '/cfd/use-quities',
                component: () => import('@/views/CFD/USEquities'),
                name: 'USEquities',
                meta: { auth: false,},
            },
        ],
    },
    {
        path: '/cfd/cryptocurrency',
        component: Layout,
        children: [
            {
                path: '/cfd/cryptocurrency',
                component: () => import('@/views/CFD/Cryptocurrency'),
                name: 'CFDCryptocurrency',
                meta: { auth: false,},
            },
        ],
    },
]


export default cfdRoutes;
