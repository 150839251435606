import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN';// element-ui lang

import enMessage from './en/message';
import zhMessage from './zh/message';

import enConfig from './en/config';
import zhConfig from './zh/config';

import enPage from './en/page';
import zhPage from './zh/page';

import enCommon from './en/common';
import zhCommon from './zh/common';

import enStatus from './en/status';
import zhStatus from './zh/status';

import en404 from './en/404';
import zh404 from './zh/404';

Vue.use(VueI18n);

const messages = {
    en: {
        ...elementEnLocale,
        ...enMessage,
        ...enConfig,
        ...enPage,
        ...enCommon,
        ...enStatus,
        ...en404,
    },
    'zh-CN': {
        ...elementZhLocale,
        ...zhMessage,
        ...zhConfig,
        ...zhPage,
        ...zhCommon,
        ...zhStatus,
        ...zh404,
    },
};

export function getLanguage() {
    const chooseLanguage = Cookies.get('language');
    if (chooseLanguage) {
        return chooseLanguage;
    }

    // if has not choose language
    let language = process.env.VUE_APP_DEFAULT_LANG || 'en';
    const locales = Object.keys(messages);
    for (const locale of locales) {
        if (language.indexOf(locale) > -1) {
            return locale;
        }
    }
    return 'en';
}
const i18n = new VueI18n({
    // set locale
    // options: en | ru | vi | zh
    locale: getLanguage(),
    // set locale messages
    messages,
});

export default i18n;
