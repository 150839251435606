<template>
    <div class="width-100">

        <div class="footer-table">
            <div class="content-box">
                <table class="width-100" cellspacing="0" cellpadding="0">
                    <tr>
                        <td rowspan="2" class="wow animated bounceInLeft">
                            <div class="footer-left">
                                <img class="footer-logo" :src="hostUrl+common.common.common.site_logo.bottom_logo" alt="">

                                <template v-for="(item,index) in layout.list">
                                    <p :key="index+'i'">{{item.company_info}}</p>
                                    <div :key="index+'j'" class="footer-link">
                                        <img src="../assets/images/icon/mail.png" alt="">
                                        <a :href="`mailto:${item.company_email}`">{{item.company_email}}</a>
                                    </div>
                                </template>
                                <div class="footer-share-box">
<!--                                    <a href="https://www.facebook.com/profile.php?id=61567354814122&mibextid=LQQJ4d" target="_blank">-->
<!--                                        <img src="../assets/images/icon/f_book.png" alt="">-->
<!--                                    </a>-->
<!--                                    <a href="https://x.com/pplix_pplix" target="_blank">-->
<!--                                        <img src="../assets/images/icon/x_icon.png" alt="">-->
<!--                                    </a>-->
                                    <template v-for="(item,index) in sharing_platform">
                                        <a v-if="item.status" :href="item.link" :key="index" target="_blank">
                                            <img :src="hostUrl+item.images" alt="">
                                        </a>
                                    </template>
                                </div>
<!--                                <p>{{$t('page.PPL_International_Limited')}}</p>-->
<!--                                <div class="footer-link">-->
<!--                                    <img src="../assets/images/icon/mail.png" alt="">-->
<!--                                    <a href="mailto:enquiry@pplgm.com">enquiry@pplgm.com</a>-->
<!--                                </div>-->

<!--                                <p>{{$t('page.PP_Link_Securities_Co')}}</p>-->
<!--                                <div class="footer-link">-->
<!--                                    <img src="../assets/images/icon/mail.png" alt="">-->
<!--                                    <a href="mailto:info@pplinksecurities.com.kh">info@pplinksecurities.com.kh</a>-->
<!--                                </div>-->
                            </div>
                        </td>
                        <td class="wow animated bounceInRight">
                            <div class="width-100 clearfix footer-nav-list">
                                <div v-for="(item,index) in headerNavList" :key="index" class="footer-nav-item">
                                    <div class="width-100">
<!--                                        <a class="footer-nav-title" :href="item.psmc_link">{{item.psmn_name}}</a>-->
                                        <router-link v-if="item.psmc_link" :to="item.psmc_link" class="footer-nav-title">{{item.psmn_name}}</router-link>
                                        <a v-if="!item.psmc_link" href="javascript:" class="footer-nav-title">{{item.psmn_name}}</a>
                                    </div>
                                    <template v-if="item.children.length">
                                        <div v-for="(cItem,cIndex) in item.children" :key="cIndex" class="width-100">
                                            <router-link :to="cItem.psmc_link" class="footer-nav-title-sub">{{cItem.psmn_name}}</router-link>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td class="wow animated bounceInRight" v-html="layout.disclaimer"></td>
                    </tr>
                </table>
            </div>
        </div>

        <div class="footer-icp wow animated bounceInLeft">
            <div class="content-box">{{layout.copyright.replace("${year}", newYear)}}</div>
        </div>
    </div>
</template>
<script>
    import {mapGetters} from "vuex";

    export default {
        name: "bottomFooter",
        computed:{...mapGetters(['common'])},
        props:{
            navList: {
                type: Array,
                default:()=>[]
            }
        },
        data(){
            return{
                headerNavList:[],
                sharing_platform:[],
                layout:{},
                hostUrl:'',
                newYear:null,
            }
        },
        created() {
            let that = this;
            that.headerNavList = that.navList
            that.hostUrl = that.common.common.domain+'/'
            that.checkLayout();
            that.newYear = new Date().getUTCFullYear();

            let sharing_platform = that.common.common.common.sharing_platform ? that.common.common.common.sharing_platform[that.$i18n.locale] : []
            sharing_platform.list.sort((a, b) => a.sort - b.sort)
            that.sharing_platform = sharing_platform.list
        },
        methods:{
            checkLayout(){
                let that = this;
                let lang = that.$i18n.locale
                that.layout = that.common.common.common.layout[lang] || that.common.common.common.layout[that.$utils.getDefaultLanguage()]
            },
        },
        watch:{
            navList(to,from){
                this.headerNavList = to
            }
        }
    }
</script>
