import Layout from "@/layout/index";

const groupNewsRoutes = [
    {
        path: '/latest-offers',
        component: Layout,
        children: [
            {
                path: '/latest-offers',
                component: () => import('@/views/GroupNews/LatestOffers/Index'),
                name: 'LatestOffers',
                meta: { auth: false,},
            },
        ],
    },
    {
        path: '/latest-offers/detail',
        component: Layout,
        children: [
            {
                path: '/latest-offers/detail',
                component: () => import('@/views/GroupNews/LatestOffers/Detail'),
                name: 'LatestOffers',
                meta: { auth: false,},
            },
        ],
    },
    {
        path: '/group-events',
        component: Layout,
        children: [
            {
                path: '/group-events',
                component: () => import('@/views/GroupNews/GroupEvents/Index'),
                name: 'GroupEvents',
                meta: { auth: false,},
            },
        ],
    },
    {
        path: '/group-events/detail',
        component: Layout,
        children: [
            {
                path: '/group-events/detail',
                component: () => import('@/views/GroupNews/GroupEvents/Detail'),
                name: 'GroupEventsDetail',
                meta: { auth: false,},
            },
        ],
    },
    {
        path: '/company-notice',
        component: Layout,
        children: [
            {
                path: '/company-notice',
                component: () => import('@/views/CompanyNotice/Index'),
                name: 'CompanyNotice',
                meta: { auth: false,},
            },
        ],
    },
    {
        path: '/company-notice/detail',
        component: Layout,
        children: [
            {
                path: '/company-notice/detail',
                component: () => import('@/views/CompanyNotice/Detail'),
                name: 'CompanyNoticeDetail',
                meta: { auth: false,},
            },
        ],
    },
]


export default groupNewsRoutes;
