import Layout from "@/layout/index";

const aboutUsRoutes = [
    {
        path: '/contact-us',
        component: Layout,
        children: [
            {
                path: '/contact-us',
                component: () => import('@/views/AboutUs/ContactUs'),
                name: 'ContactUs',
                meta: { auth: false,},
            },
        ],
    },
    {
        path: '/faq',
        component: Layout,
        children: [
            {
                path: '/faq',
                component: () => import('@/views/AboutUs/FAQ'),
                name: 'FAQ',
                meta: { auth: false,},
            },
        ],
    },
    {
        path: '/ppl-international',
        component: Layout,
        children: [
            {
                path: '/ppl-international',
                component: () => import('@/views/AboutUs/PPLGMGroup'),
                name: 'PPLGMGroup',
                meta: { auth: false,},
            },
        ],
    },
    {
        path: '/regulatory-compliance',
        component: Layout,
        children: [
            {
                path: '/regulatory-compliance',
                component: () => import('@/views/AboutUs/RegulatoryCompliance'),
                name: 'RegulatoryCompliance',
                meta: { auth: false,},
            },
        ],
    },
]


export default aboutUsRoutes;
